.login {
  display: flex;
  flex-grow: 1;
  a {
    text-decoration: none;
    user-select: none;
    &:focus,
    &:visited,
    &:active {
      outline: none;
    }
    &:hover {
      text-decoration: underline;
    }
  }
  button {
    outline: none;
    cursor: pointer;
  }
  &__left-sec {
    background-size: 80% !important;
    flex-grow: 4;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 1;
      background-color: var(--primary-color);
      opacity: 0.1;
    }

    h1 {
      z-index: 2;
      color: #fff;
      padding: 5vh 5vh 0 5vh;
      font-size: 4vh;
      line-height: 5vh;
      position: absolute;
      width: 70%;
      text-shadow: 0 5px 5px rgba(0, 0, 0, 0.5);
    }
    @include respond(tab-land) {
      flex-grow: 1;
    }
  }
  &__right-sec {
    background: var(--surface-color);
    flex-grow: 1;
    position: relative;
    height: 100%;
    flex-direction: column;
    display: flex;
    justify-content: center;
  }
  &__form-wrapper {
    // width: 80%;
    // position: absolute;
    width: 90%;
    // left: 50%;
    // top: 50%;
    // transform: translate(-50%, -50%);
    margin: 0 auto;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__logo {
    width: 150px;
    display: block;
    margin: 0 auto;
    margin-bottom: 5vh;
    text-align: center;
    font-size: 20px;

    img {
      width: 150px;
    }

    .desktop-logo {
      display: block;
    }

    .mob-logo {
      display: none;
    }
  }

  &__form {
    width: 80%;
    margin: 0 auto;
    &__inputs {
      position: relative;
      // padding: 10px;
      // &::before {
      //   border-radius: 8px;
      //   content: "";
      //   position: absolute;
      //   top: 0;
      //   left: 0;
      //   width: 100%;
      //   height: 100%;
      //   background: rgba(255, 255, 255, 0.5);
      //   backdrop-filter: blur(8px);
      // }
    }
    .login-field {
      // padding: 15px 20px;
      // display: block;
      // margin-bottom: 10px;
      // width: 100%;
      // border: none;
      border-radius: 8px;
      // box-shadow: 0 10px 30px rgba(20, 95, 218, 0.3);
      // box-sizing: border-box;
      // outline: none;
      // background: #fff;
    }
  }
  &-action {
    padding: 1vh 0% 5vh 0%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
  .btn-forgot-pass {
    @include respond(tab-port) {
      color: var(--solids-white);
    }
  }

  // .login-action span {
  //   padding-left: 10px;
  // }
  &__btn {
    width: 60px;
    height: 60px;
    border: 0;
    border-radius: 50%;
    background: var(--primary-color);
    color: var(--solids-white);
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 10px 30px rgba(var(--light-box-shadow2), 0.3);
    img {
      width: 17px;
    }
  }

  // .login-action span {
  //   padding-left: 10px;
  // }
  // &__btn {
  //   // width: 60px;
  //   // height: 60px;
  //   // border: 0;
  //   // border-radius: 50%;
  //   // background: #0058ff;
  //   // display: flex;
  //   // justify-content: center;
  //   // align-items: center;
  //   box-shadow: 0 10px 30px rgba(20, 95, 218, 0.3);
  //   img {
  //     width: 17px;
  //   }
  // }
  &__error {
    width: 100vw;
    position: fixed;
    top: 0;
    padding: $spacing-1 * 4;
    background-color: var(--danger-red-font);
    color: #fff;
    font-size: $txt-14;
    text-align: center;
    z-index: 99;
  }

  .links {
    text-align: center;
  }

  .app-link {
    padding-bottom: 10px;
    display: inline-block;
  }

  .app-link img {
    width: 130px;
  }

  .privacy-section {
    $margin-size: 15px;
    // position: absolute;
    // bottom: 16px;
    // width: calc(100% - #{$margin-size} * 2);
    margin: 0 $margin-size $margin-size / 3 $margin-size;

    // .privacy {
    //   position: absolute;
    //   left: 20px;
    //   bottom: 16px;
    // }

    .truste {
      // position: absolute;
      // right: 20px;
      // bottom: 10px;
      img {
        width: 70px;
      }
    }
  }

  .app-link-holder {
    justify-content: center;
    align-items: center;
    img {
      width: 17px;
    }
  }
  &__error {
    width: 100vw;
    position: fixed;
    top: 0;
    padding: $spacing-1 * 4;
    background-color: var(--danger-red-font);
    color: #fff;
    font-size: $txt-14;
    text-align: center;
    z-index: 99;
  }

  .links {
    text-align: center;
  }

  .app-link {
    padding-bottom: 10px;
    display: inline-block;
  }

  .app-link img {
    width: 130px;
  }

  .privacy-section {
    // .privacy {
    //   position: absolute;
    //   left: 20px;
    //   bottom: 16px;
    // }

    .truste {
      // position: absolute;
      // right: 20px;
      // bottom: 10px;
      img {
        width: 70px;
      }
    }
  }

  .app-link-holder {
    justify-content: center;
    a:first-child {
      margin-right: 5px;
    }
  }
  @include respond(tab-land) {
    // .login {
    // }
    &__left-sec {
      flex-grow: 6;
      background-position: 50% 70% !important;
    }
  }

  @include respond(tab-port) {
    // .login {
    position: relative;
    height: inherit;
    &__left-sec {
      flex: none;
      width: 100%;
      height: inherit;
      position: absolute;
      // width: 100%;
      background-size: 160% !important;
      background-position: center !important;
      &:before {
        background-color: var(--primary-text-color);
        opacity: 0.7;
      }
      h1 {
        width: 100%;
        padding: 5%;
      }
    }

    &__right-sec {
      background: none;
      position: absolute;
      height: inherit;
      width: 100%;
      // left: 50%;
      // top: 50%;
      z-index: 2;
      // transform: translate(-50%, -50%);
    }

    &__logo {
      margin-bottom: 3vh;
      .desktop-logo {
        display: none;
      }

      .mob-logo {
        display: block;
      }
    }
    // &__form-wrapper {
    //   top: 55%;
    // }
    &__form {
      width: 100%;
      // .login-field {
      //   padding: 10px 20px;
      // }
      max-width: 400px;
      &__inputs {
        // position: relative;
        padding: 15px 20px 20px 20px;
        // margin: 0 auto;
        &::before {
          z-index: -1;
          border-radius: 10px;
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(rgba(255, 255, 255, 0.1) 5%, rgba(255, 255, 255, 0.5));
          backdrop-filter: blur(10px);
          // -webkit-backdrop-filter: blur(10px) saturate(100%) contrast(45%) brightness(130%);
        }
      }
    }
    &-action {
      padding: 1vh 0% 3vh 0%;
    }
    &__btn {
      width: 50px;
      height: 50px;
      color: var(--primary-color);
      background: var(--solids-white);
    }
    // }
    a,
    a:hover {
      color: #fff;
      // text-decoration: underline;
      font-size: 13px;
    }

    p {
      color: #fff;
    }
  }
  @include respond(phone) {
    &__form-wrapper {
      padding-top: 10rem;
    }
  }
  @include respond-height() {
    &__left-sec {
      h1 {
        visibility: hidden;
      }
    }
    &__form-wrapper {
      padding-top: 0;
    }
  }
  @include respond-height(480px) {
    height: 115%;
  }
  @include respond-height(350px) {
    height: 130%;
  }
}
