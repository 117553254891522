.subscriber-switch-dialog-content--wrapper {
  .search-loader {
    margin-top: $spacing-1 * 10;
  }
  .search-result {
    min-height: 100px;
    max-height: calc(100vh - 300px);
    @include respond(tab-land) {
      max-height: initial;
    }
  }
}
