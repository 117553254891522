.quick-filter-menu--Wrapper {
  max-width: calc(95vw - 300px);
  min-width: 200px;
  padding: 0 20px;

  @include respond(small-desktop) {
    min-width: 100%;
    max-width: 100%;
    padding: 10px {
      right: 0;
    }
  }

  .header {
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    // @include respond(tab-port) {
    //   align-items: flex-start;
    // }
    > div {
      @include respond(tab-port) {
        align-items: flex-start;
      }
    }
    .title > h4 {
      font-size: $txt-18;
      margin: 0 {
        right: 10px;
      }
    }

    .counter {
      p {
        margin: 0;
      }
    }
  }

  .content {
    .filter-options-group {
      max-height: 320px;
      @include respond(tab-port) {
        max-height: 150px;
        min-width: 100%;
        max-width: 100%;
        margin-bottom: $spacing-1 * 5;
      }
      max-height: 350px;
      // margin-right: $spacing-1 * 5;
      border-collapse: collapse;
      min-width: 90px;
      max-width: 200px;
      flex-shrink: 0;
      @include respond(tab-port) {
        padding-right: 10px;
      }
    }

    .filter-item-title {
      font-size: $txt-16;
      color: rgb(97, 97, 97);
      max-width: 180px;
      margin: {
        bottom: $spacing-1;
      }
    }
    .filter-item-list-container {
      padding: $spacing-1 * 2 {
        left: 0;
        right: 2px;
      }
      font-size: $txt-16;
      // columns: #383838;

      .filter-item {
        padding: $spacing-1 * 4;
        margin: $spacing-1 0;
        background-color: rgb(218, 218, 218);
        max-width: 180px;
        min-width: 80px;
        flex-shrink: 0;
        border-radius: 3px;
        text-align: center;
        font-size: $txt-14;
        cursor: pointer;
        &:first-child {
          margin-top: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }
        @include respond(tab-port) {
          margin: 0 $spacing-1;
          &:first-child {
            margin-left: 0;
          }
          &:last-child {
            margin-right: 12px;
          }
        }
        &:hover {
          background: rgba(0, 0, 0, 0.2);
        }

        &.selected {
          background-color: rgba(var(--filter-btn-color), 1);
          color: var(--primary-color);
          &:hover {
            background-color: rgba(var(--filter-btn-color-hover), 1);
          }
        }
      }
    }
  }
}
