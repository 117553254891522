.subscriber-social-media {
    width: 100%;
    .container-post {
        max-width: $small-desktop-breakpoint;
        margin: 0 auto;
    }
    .container-form {
        max-width: $small-desktop-breakpoint;
        margin: 0 auto;
    }
    // .card-area {
    //     box-shadow: $box-shadow1;
    //     background-color: white;
    //     padding: $even-spacing * 4;
    //     border-radius: 6px;
    //     margin-top: 25px;
    // }
    .card-layout {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        row-gap: 10px;
        column-gap: 10px;
        @include respond(tab-port) {
            grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
            row-gap: 10px;
            column-gap: 10px;
        }
        @include respond(phone) {
            grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
            row-gap: 10px;
            column-gap: 10px;
        }
        box-shadow: $box-shadow1;
        background-color: white;
        padding: $even-spacing * 4;
        border-radius: 6px;
        margin-top: 25px;
        &:hover {
            box-shadow: $box-shadow1-hover;
        }
    }
    .container-generation {
        height: 220px;
        @include respond(tab-port) {
            height: 220px;
        }
        @include respond(phone) {
            height: 210px;
        }
        box-shadow: $box-shadow1;
        &:hover {
            box-shadow: $box-shadow1-hover;
        }
        &-post {
            justify-content: space-between;
            align-items: center;
            flex-direction: column;
            display: flex;
            position: relative;
            transform: translate(-50%, -50%);
            top: 50%;
            left: 50%;
            cursor: pointer;
        }
        p {
            color: var(--primary-color);
        }
    }
    .container-thumbnail {
        transition: 0.5s ease-in;
        position: relative;
        display: inline-block;
        overflow: hidden;
        height: 220px;
        @include respond(tab-port) {
            height: 220px;
        }
        @include respond(phone) {
            height: 210px;
        }
        box-shadow: $box-shadow1;
        #logo {
            width: 30px;
            height: 30px;
            position: absolute;
            z-index: 1;
            top: 10px;
            left: 10px;
        }
        #thumbnails {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
        &::after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(transparent 40%, #000000 100%);
        }
        // &:hover::after {
        //     content: "";
        //     position: absolute;
        //     left: 0;
        //     top: 0;
        //     width: 100%;
        //     height: 100%;
        //     background: linear-gradient(transparent 50%, #000000 90%);
        // }
    }
        
    .menu-btn {
        background: #fff;
        height: 27px;
        width: 27px;
        position: absolute;
        top: 10px;
        right: 10px;
        border-radius: 50%;
        z-index: 3;
        box-shadow: 0 1px 2px 0 rgba($color: #000000, $alpha: 0.1);
        transition: all 0.3s ease-out;
        &-more {
            box-shadow: 0 1px 2px 0 rgba($color: #000000, $alpha: 0.3);
            position: absolute;
            top: 0px;
            right: 0px;
            z-index: 2;
            width: 27px;
            height: 27px;
        }
        .action-btn {
            position: absolute;
            top: 0;
            right: 0;
            width: 0;
            transition: all 0.3s ease-out;
            overflow: hidden;
            border-radius: 50px;

            &-edit {
                width: 25px;
                height: 25px;
                position: absolute;
                top: 1px;
                right: 66px;
            }
            &-link {
                width: 25px;
                height: 25px;
                position: absolute;
                top: 1px;
                right: 36px;
            }
        }
        &:hover {
            transition: all 0.3s ease-out;
            & .action-btn {
                position: absolute;
                top: 0;
                right: 0;
                transition: all 0.3s ease-out;
                height: 27px;
                width: 100px;
                background: #fff;
                z-index: 1;
            }
        }
    }
    .post-description {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: absolute;
        bottom: 10px;
        left: 10px;
        color: #fff;
        z-index: 1;
        h4 {
            font-weight: bold;
        }
        p {
            font-style: italic;
            font-size: 10px;
        }
    }
    .pagination {
        display: flex;
        justify-content: center;
        margin: 20px;
    }
}
