.service-appointment {
    max-width: 600px;
    margin: 0 auto;
    .float-right {
        float: right;
    }
    .flex-start {
        align-items: flex-start;
    }
    .justify-start {
        justify-content: flex-start;
    }
}
