.forms-in-chat-d {
    width: 100%;

    .card {
        &-title {
            @extend %txt;
            color: var(--primary-text-color);
            font-size: $txt-16;
            font-weight: 500;
            margin-bottom: $even-spacing * 4;
        }

        &-body {
            box-shadow: $box-shadow1;
            background-color: white;
            padding: $even-spacing * 4;
            border-radius: 6px;

            &:hover {
                box-shadow: $box-shadow1-hover;
            }
        }

        @include respond(tab-port) {
            width: 100%;
        }
    }

    .h-20 {
        height: 20px;
    }

    .formsinchat-drop {
        section {
            padding: 10px;
            box-shadow: 0 2px 5px rgba(var(--light-box-shadow), 0.1), 0 10px 20px rgba(var(--light-box-shadow), 0.1);
            background-color: #00256817;
            border-radius: 6px;
        }

        .d-flex {
            display: flex;
        }
    }

    .l-m-60 {
        margin-left: 60px;
    }

    .form-ele-card {
        padding: "10px";
        width: "100%";
    }

    .b-l-primary {
        border-left: 4px solid var(--primary-color);
    }

    .drop-area-box {
        border: 2px dotted var(--primary-color);
    }

    .form-ele-list-card {
        .form-ele-card-content {
            padding: 10px 10px 10px 2px;
            border-left: 4px solid var(--primary-color);

            .ele-card-box {
                border: 1px solid var(--primary-color);
                padding: 10px;
            }

            .template-btn {
                z-index: 2;
            }
        }
    }
}