.tab-selector {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid var(--primary-color);
    border-radius: 35px;
    padding: 3px;
    width: max-content;
    height: max-content;

    &__toggle-button-group-container {
        display: flex;

        button {
            height: 35px;
            width: 100px;
            text-transform: capitalize;
            border-radius: 35px;
            color: var(--primary-color);
            font-size: $txt-14;
            transition: background-color 0.2s ease-in-out;

            &.selected {
                background-color: var(--primary-color);
                color: #fff;
            }
        }
    }
}