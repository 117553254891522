.brand-logo {
  .full-logo {
    height: 50px;
    width: 170px;
    margin: 0 auto;
    img {
      height: 100%;
      width: 100%;
    }
  }

  .small-logo {
    height: 50px;
    width: 50px;
    margin: 0 auto;
    position: relative;
    img {
      height: 100%;
      width: 92%;
      object-fit: cover;
    }
  }
}
