.confirm-invoices {
  &__wrapper {
    width: 100%;
    min-height: 100%;
    position: relative;
  }
  &__bg-holder {
    position: absolute;
    height: 90vh;
    // width: 100%;
    top: 20px;
    opacity: 0.3;
    // z-index: -1;
    img {
      width: 100%;
      margin: 0 auto;
      display: block;
      opacity: 0.4;
    }
    @include respond(tab-port) {
      height: unset;
      width: 100%;
    }
  }

  &__heading {
    font-size: $txt-20;
    color: var(--primary-text-color);
    font-weight: 600;
    margin-bottom: $spacing-1 * 5;
  }

  &__list {
    background: var(--solids-white);
    //
    border-radius: 5px;
    box-shadow: $box-shadow1;
    overflow: hidden;
  }
  &__payment {
    background: var(--solids-white);
    border: $border-1;
    border-radius: 5px;
    box-shadow: $box-shadow1;
    padding: $spacing-1 * 20;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-width: 700px;
    @include respond(tab-land) {
      min-width: 500px;
    }
    @include respond(tab-port) {
      flex-direction: column-reverse;
      justify-content: flex-start;
      min-width: 0px;
    }
  }

  &__payment-mode {
    color: var(--primary-text-color);
    font-weight: 600;
    .heading {
      font-size: $txt-16;
      margin: $spacing-1 * 4 0;
    }
    .checkbx-label {
      margin: {
        left: $spacing-1 * 5;
      }
    }
  }
  &__total-amt {
    margin-left: $spacing-1 * 20;
    @include respond(tab-port) {
      margin-left: initial;
      margin-bottom: $spacing-1 * 10;
    }
    .heading {
      color: var(--primary-text-color);
      font-weight: 600;
      font-size: $txt-18;
      margin: $spacing-1 * 4 0;
    }
    .amount {
      color: var(--primary-color);
      font-weight: 600;
      font-size: $txt-28;
    }
  }

  .card {
    border-bottom: $border-1;
    padding: $spacing-1 * 10;
    @include respond(tab-land) {
      min-width: 90vw;
    }
    // line-height: 1;
    .label-txt,
    &__invoice-number {
      color: var(--primary-color);
      font-weight: 600;
    }
    &__subscriber,
    &__invoice-date {
      color: var(--primary-text-color);
      font-weight: 600;
      margin-bottom: $spacing-1;
    }

    &__heading {
      font-size: $txt-14;
      margin: {
        bottom: $spacing-1 * 5;
      }
    }

    &__subscriber {
      font-size: $txt-16;
      font-weight: 600;
    }
    &__break-down {
      width: 100%;
      margin-top: $spacing-1 * 5;
      font-size: $txt-14;
      color: var(--primary-text-color);
      .pull-right {
        text-align: right;
      }

      .dt-cell {
        &:first-child {
          padding-right: $spacing-1 * 10;
        }
      }
      .total-amt {
        font-weight: 600;
        color: var(--primary-text-color);
      }
      .invoice__break-down {
        width: 100%;
      }
    }
  }

  .ar {
    text-align: right;
  }

  .inv-table {
    border-collapse: collapse;
    color: var(--primary-text-color);
    text-align: center;

    .dt-cell {
      padding: $spacing-1 * 10 $spacing-1 * 10;
      vertical-align: middle;
    }
    .dt-row {
      border-bottom: $border-1;
    }
    .table-head {
      background: var(--primary-color);
      color: var(--solids-white);
      font-weight: 600;
      .dt-cell {
        padding: $spacing-1 * 10;
      }
    }

    &__subscriber {
      font-size: $txt-18;
      font-weight: 600;
    }
    &__invoice-date {
      font-weight: 600;
      font-size: $txt-14;
    }
    &__invoice-num {
      color: var(--primary-color);
      font-weight: 600;
    }
    &__view-invoice-link {
      color: var(--primary-color);
      font-weight: 600;
    }
  }

  &__controls {
    width: inherit;
    margin: $spacing-1 * 10 0;
    justify-content: space-between;

    .restrict-height {
      height: 40px;
      width: 75px;
    }
  }
}
