.working-hour-component {
    width: 100%;
    overflow-x: auto;
    padding: 2px;
    h4 {
        margin-bottom: 8px;
    }
    table.working-hour-table {
        width: 100%;
        // border-spacing: 1em 1em;
        border-collapse: collapse;
        border-radius: 4px;
        border-style: hidden; /* hide standard table (collapsed) border */
        box-shadow: 0 0 0 1px var(--primary-color); /* this draws the table border  */
        thead {
            tr {
                &:nth-child(1) {
                    background-color: var(--primary-color);
                }
                th {
                    padding: 8px 16px;
                    color: white;
                }
            }
        }
        tr {
            th,
            td {
                text-align: left;
                padding: 4px;
                padding-left: 16px;
            }
            .right {
                text-align: right;
                padding-left: 4px;
            }
            .center {
                text-align: center;
                padding-left: 4px;
            }
        }
    }
}
