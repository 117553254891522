.in-progress-page {
  min-height: 100%;
  justify-content: center;
  &__animation-holder {
    width: 720px;
    height: 540px;
    border-radius: 5px;
    overflow: hidden;
    margin: 0 auto;
    display: block;
    // box-shadow: $box-shadow1;
    position: relative;
    @include respond(tab-port) {
      width: 100%;
      height: auto;
    }

    img {
      height: 100%;
      width: 100%;
      display: block;
    }
    .label {
      margin-top: $spacing-1 * 10;
      font-size: $txt-24;
      color: var(--primary-color);
      font-weight: bold;
      text-align: center;
      position: absolute;
      bottom: 30px;
      width: 100%;
    }
  }
}
