#organisation-view-area {
    margin-right: $even-spacing;
    margin-bottom: $even-spacing * 4;
    height: 100%;
    .step-section {
        width: 100%;
    }
    form {
        max-width: $desktop-breakpoint;
        margin: 0 auto;
        $step-fill: var(--muted-text-color);
        .MuiTextField-root {
            margin-top: $even-spacing * 2;
            margin-bottom: $even-spacing * 2;
        }
        .remove-margin {
            .MuiTextField-root {
                margin-top: 0;
                margin-bottom: 0;
            }
        }
        .MuiStepIcon {
            // &-root {
            //     fill: $step-fill;
            // }
            &-active {
                fill: var(--primary-color);
            }
            &-completed {
                fill: var(--primary-color);
                opacity: 0.7;
            }
        }
        .MuiStepLabel-label {
            // color: $step-fill;
            $self: ".MuiStepLabel";
            &#{$self}-active {
                font-size: $txt-20;
                // color: black;
            }
        }
    }
    section {
        margin-top: 16px;
        background-color: #fff;
        padding: $even-spacing * 6 $even-spacing * 6 $even-spacing * 4 $even-spacing * 6;
        border-radius: 10px;
        box-shadow: $box-shadow1;
        transition: box-shadow 0.3s ease-out;
        @include respond(phone) {
            overflow: auto;
            width: 300px;
        }
        @include respond(xs-phone) {
            width: 250px;
        }
        &:hover {
            box-shadow: $box-shadow1-hover;
        }
    }
    h1,
    h2,
    h3,
    h4 {
        overflow-wrap: break-word;
        word-break: break-word;
        margin-bottom: $even-spacing * 2;
    }

    h2 {
        color: var(--primary-text-color);
    }

    .services-section {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: $even-spacing * 4;
        @include respond(tab-land) {
            grid-template-columns: 1fr;
        }
    }

    .working-hours-section {
        margin-top: 8px;
        display: grid;
        grid-template-columns: auto auto;
        row-gap: 10px;
        column-gap: 10px;

        @include respond(tab-land) {
            grid-template-columns: auto;
        }
        @include respond(phone) {
            & > div {
                padding-right: 24px;
            }
        }
    }
}
