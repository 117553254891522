.picker-container {
    .image-picker-container {
        position: relative;
        width: 200px;
        height: 200px;
        margin: 0 auto;
        $self: ".image-picker";
        #{$self} {
            position: absolute;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            .input-dp {
                width: 100%;
                height: 100%;
                opacity: 0;
                overflow: hidden;
                position: absolute;
                border-radius: 50%;
                cursor: pointer;
                z-index: 3;
                &:hover + #{$self}__overlay {
                    opacity: 0.5;
                }
            }
            &__overlay {
                width: inherit;
                height: inherit;
                opacity: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #808080;
                border-radius: 50%;
                transition: all 0.3s linear;
                z-index: 1;
                // svg {
                //     color: white;
                // }
            }
            img.image-selected {
                width: 100%;
                height: 100%;
                position: absolute;
                border-radius: 50%;
                min-width: 100px;
                min-height: 100px;
                z-index: 0;
                border: 2px solid #808080;
            }
        }

        @include respond(tab-port) {
            width: 100px;
            height: 100px;
        }
    }
    .crop-tools {
        position: relative;
        .crop-container {
            margin-top: 10px;
            position: relative;
            height: 400px;
            @include respond(tab-port) {
                height: 200px;
            }
        }
    }
}
