.payment-invoices {
  // &__wrapper {
  // }
  .position-sticky {
    position: sticky;
    top: 60px;
    height: calc(100vh - 80px);
  }

  .invoices {
    margin-left: $spacing-1 * 5;
    @include respond(tab-land) {
      margin-left: initial;
    }
  }
  // .payment-summary {
  //   // paymeny card
  // }

  .invoices {
    &__list {
      &__header {
        justify-content: space-between;
        margin-bottom: $spacing-1 * 5;
        .title {
          font-size: $txt-20;
          color: var(--primary-text-color);
          font-weight: 600;
        }
        .selection-count {
          font-size: $txt-14;
        }
      }
    }

    &__summary {
      align-self: flex-start;
      background: var(--primary-color);
      min-height: 100px;
      min-width: 200px;
      padding: $spacing-1 * 10;
      border-radius: 5px;
      margin-bottom: $spacing-1 * 10;
      color: var(--solids-white);

      &__total-due {
        margin-bottom: $spacing-1 * 10;
        justify-content: space-between;
      }
      &__total-selected {
        justify-content: space-between;
      }

      .label {
        font-size: $txt-12;
      }

      .value {
        font-size: $txt-24;
        margin-right: $spacing-1 * 10;
      }

      .invoice-count {
        font-size: $txt-12;
      }
      .btn {
        width: 140px;
        font-size: $txt-14;
        font-weight: 600;
        margin-left: $spacing-1 * 10;
      }

      .btn {
        &--full-pay {
          background-color: var(--solids-green-light);
          color: #000fff;
        }
        &--pay-selc {
          background-color: var(--surface-color);
          color: var(--primary-color);
          &:disabled {
            background-color: var(--muted-text-color2);
          }
        }
      }

      @include respond(phone) {
        min-width: 100%;
        &__total-due {
          .parent-row {
            align-items: flex-start;
          }
          .value {
            word-break: break-all;
            font-size: $txt-20;
          }
          .invoice-count {
            padding-top: 5px;
          }
        }
      }
    }
  }

  .invoice {
    &__card {
      box-shadow: $box-shadow1;
      min-height: 60px;
      margin: $spacing-1 * 10 0;
      background: var(--solids-white);
      border-radius: 5px;
      padding: $spacing-1 * 10;
      position: relative;
      border: 2px solid var(--transparent-border);

      &.selected {
        border: 2px solid var(--primary-color);
      }
    }
    &__info {
      justify-content: space-between;
      .dt-table {
        margin-bottom: $spacing-1 * 5;
      }
      .breakable {
        justify-content: flex-end;
      }
    }
    &__label {
      padding: {
        right: $spacing-1 * 8;
        bottom: $spacing-1 * 3;
      }
    }

    &__id {
      color: var(--primary-color);
      font-size: $txt-14;
      font-weight: 600;
    }
    &__subscriber {
      color: var(--primary-text-color);
      font-size: $txt-18;
      font-weight: 600;
      margin: $spacing-1 * 3 0;
    }
    &__row {
      color: var(--primary-text-color);
      font-size: $txt-14;
      .badge {
        margin-left: $spacing-1 * 5;
      }
    }
    &__break-down {
      text-align: right;
      .invoice__label {
        padding: {
          bottom: 0;
        }
      }
    }
    &__view-link {
      color: var(--primary-color);
      font-weight: 600;
      text-decoration: underline;
    }
    &__select-mark {
      padding: 0 {
        left: $spacing-1 * 10;
      }
      .checkbox {
        height: 20px;
        width: 20px;
        border: 1px solid var(--primary-color);
      }
    }
  }

  .badge {
    padding: $spacing-1 $spacing-1 * 5;
    border-radius: 30px;
    font-size: $txt-14;
    font-weight: 600;
    color: var(--primary-text-color);

    &--danger {
      background-color: var(--danger-red-bg);
      color: var(--danger-red-font);
    }

    &--success {
      background-color: var(--success-green-bg);
      color: var(--success-green-font);
    }
    &--warning {
      background-color: var(--warning-yellow-bg);
      color: var(--warning-yellow-font);
    }
  }
}
.invoice-filter-wrapper {
  width: 300px;
  .filter-card {
    background: var(--solids-white);
    border: $border-1;
    box-shadow: $box-shadow1;
    padding: $spacing-1 * 10;
    border-radius: 5px;

    @include respond(tab-land) {
      background: none;
      border: none;
      box-shadow: none;
      padding: 0;
      border-radius: 0;
    }
  }

  @include respond(tab-land) {
    width: 100%;
    padding: $spacing-1 * 10;
  }
  .option-group--wrapper {
    margin-bottom: $spacing-1 * 5;
    .title {
      font-size: $txt-16;
      color: var(--primary-text-color);
      font-weight: 600;
      margin-bottom: $spacing-1 * 4;
    }

    .option-list {
      overflow: auto;
      &.subscriber-options {
        max-height: calc(100vh - 600px);
        min-height: 50px;
        @include respond(tab-port) {
          max-height: calc(100vh - (100vh * 70 / 100));
        }
      }
    }

    // .option {
    // }
  }

  .date-group--wrapper {
    margin-bottom: $spacing-1 * 5;
    padding: 10px;
    .title {
      font-size: $txt-16;
      color: var(--primary-text-color);
      font-weight: 600;
      margin-bottom: $spacing-1 * 4;
    }

    .option-list {
      overflow: auto;
      &.subscriber-options {
        max-height: calc(100vh - 600px);
        min-height: 50px;
        @include respond(tab-port) {
          max-height: calc(100vh - (100vh * 70 / 100));
        }
      }
    }

    // .option {
    // }
  }
  .close-btn {
    color: var(--primary-text-color);
    text-align: right;
  }
}
