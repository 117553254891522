.sales-portal-monitor {
  hr {
    border-color: var(--surface-color);
  }
  .timeline-container {
    max-width: $small-desktop-breakpoint;
    margin: 0 auto;
    .search-input {
      width: 300px !important;
      @include respond(phone) {
        width: 100% !important;
      }
    }
  }
  .timeline-accordian {
    $self: ".timeline-accordian";
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px;
      // border-bottom: 1px solid var(--muted-text-color);
      &__current-state {
        text-align: right;
      }
    }
    &__body-container {
      border-top: 1px solid var(--muted-text-color);
      #{$self}__body {
        position: relative;
        &__group {
          display: flex;
          flex-direction: column;
          .date {
            text-align: left;
          }
          &__grid-area {
            .icon {
              z-index: 9;
              background-color: white;
            }
            &__desc {
              // align-items: flex-start;
              display: flex;
              justify-content: space-between;
              .title {
                word-break: break-all;
                font-weight: 500;
              }
              .value {
                word-break: break-all;
                margin-left: 12px;
              }
              @include respond(phone) {
                flex-direction: column;
                .title {
                  display: block;
                }
                .value {
                  margin-left: 0px;
                }
              }
            }
          }
          &:before {
            content: "";
            position: absolute;
            top: 20px;
            left: 83px;
            bottom: 12px;
            width: 1px;
            background: var(--primary-color);
          }
          // &:after {
          //   content: "";
          //   display: table;
          //   clear: both;
          // }
        }
      }
    }
  }

  .grid-container {
    display: grid;

    grid-template-columns: 4rem 1fr 10fr;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    // for mobile view
    @include respond(phone) {
      grid-row-gap: 10px;
      grid-template-columns: 4rem 1fr 9fr;
      position: relative;
      // right: 80px;
    }
  }

  .input-container {
    width: 50%;
    @include respond(phone) {
      width: 100%;
    }
  }

  @include respond(phone) {
    margin-left: 0;
    overflow-x: hidden;
  }
}
