@import url("https://fonts.googleapis.com/css2?family=Oxanium:wght@500&display=swap");

.b-overview {
  .card-holder {
    background: var(--solids-white);
    box-shadow: $box-shadow1;
    border-radius: 10px;
    border: $border-1;
    padding: $spacing-1 * 10;
    justify-content: space-evenly;
  }
  .card {
    width: 30%;
    min-height: 200px;
    margin-right: $spacing-1 * 10;
    &:last-child {
      margin-right: 0;
    }
    // padding: $spacing-1 * 10 $spacing-1 * 30;
    border-radius: 10px;
    position: relative;
    @include respond(tab-port) {
      width: 100%;
      margin-bottom: $spacing-1 * 10;
      margin-right: 0;
      &:last-child {
        margin-bottom: 0;
      }
      padding: $spacing-1 * 10;
      max-width: initial;
      min-width: initial;
    }
    @include respond(xs-phone) {
      padding: $spacing-1 * 2;
    }
  }

  &__payment-card {
    &.pending {
      background: rgb(255, 67, 138);
      background: linear-gradient(
        126deg,
        rgba(255, 67, 138, 1) 35%,
        rgba(229, 0, 0, 1) 100%
      );
    }

    &.cleared {
      background: rgb(0, 222, 146);
      background: linear-gradient(
        135deg,
        rgba(0, 222, 146, 1) 35%,
        rgba(0, 144, 129, 1) 100%
      );
    }

    &.loading {
      background: rgb(208, 230, 255);
      background: linear-gradient(
        133deg,
        rgba(208, 230, 255, 1) 35%,
        rgba(212, 212, 212, 1) 100%
      );
    }

    .content {
      color: var(--solids-white);
    }
    .heading {
      font-size: $txt-16;
      font-weight: 600;
    }
    .amount {
      font-size: $txt-20;
      font-weight: 600;
      margin: $spacing-1 * 5 auto;
    }
    .message {
      font-size: 11px;
    }

    .btn {
      // background-color: var(--solids-green-light);
      color: #000fff;
      border: 1px solid #000fff;
      width: 120px;
      margin-bottom: $spacing-1 * 5;
    }

    .bg {
      position: absolute;
      right: 0%;
      opacity: 0.2;
      bottom: 2%;
      height: 81px;
    }
  }

  &__address-card {
    border: 1px solid var(--primary-color);
    background: var(--surface-color2);

    .content {
      align-self: center;
      max-width: 80%;
      position: relative;
      z-index: 10;
    }
    .heading {
      font-size: $txt-16;
      font-weight: 600;
      color: var(--primary-text-color);
      margin: {
        bottom: $spacing-1 * 5;
      }
    }

    .address {
      font-size: $txt-12;
      color: var(--primary-text-color);
      text-align: left;
      p {
        line-height: 15px;
      }
      .btn-link {
        padding: {
          left: 0;
          right: 0;
        }
        text-decoration: underline;
      }
    }

    .bg {
      height: 100%;
      width: 100%;
      opacity: 0.1;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__credit-card {
    .heading {
      color: var(--primary-text-color);
      font-size: $txt-16;
      font-weight: 600;
      text-align: center;
      margin: {
        bottom: $spacing-1 * 1;
      }
    }
    .cc-shape-wrapper {
      width: 100%;
      position: relative;
      max-width: 300px;
    }
    .cc-content {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      padding: $spacing-1 * 10;
      z-index: 10;
      border-radius: 10px;
      color: var(--solids-white);
      @include respond(small-desktop){
        padding: $spacing-1 * 5;
      }
      @include respond(xs-phone) {
        padding: $spacing-1 * 5;
      }
      &.initial {
        background: #639ad1;
      }
      &.blue-card {
        background: rgb(0, 115, 255);
        background: linear-gradient(
          133deg,
          rgba(0, 113, 253, 1) 41%,
          rgba(0, 8, 165, 1) 75%
        );
      }

      & > div {
        flex-grow: 1;
        align-items: center;
      }
    }

    .label {
      font-size: $txt-10;
      font-style: italic;
      letter-spacing: 1px;
    }

    .card-number {
      font-family: "Oxanium", cursive;
      align-self: center;
      justify-self: center;
      width: 100%;
      text-align: center;
      text-align-last: justify;
      font-size: $txt-20;
      @include respond(small-desktop){
        font-size: $txt-18;
      }
      @include respond(xs-phone) {
        font-size: $txt-14;
      }
      // padding-top: 10%;
    }

    .card-type-logo {
      width: 25px;
      height: auto;
    }

    .info {
      justify-content: flex-end;
    }

    .expiry,
    .name {
      font-size: $txt-12;
      font-weight: 600;
    }

    .expiry {
      margin: {
        right: $spacing-1 * 30;
      }
    }

    .no-card {
      font-size: $txt-12;
      text-align: center;
    }

    .button {
      justify-content: flex-end;

      .btn-link {
        padding: $spacing-1 * 2;
        text-decoration: underline;
      }
    }
  }
  .payment-history {
    margin: {
      top: $spacing-1 * 20;
      bottom: $spacing-1 * 20;
    }
    .card-holder {
      height: 300px;
      width: 100%;
      position: relative;
    }

    .heading {
      font-size: $txt-18;
      font-weight: 600;
      color: var(--primary-text-color);
      margin: {
        bottom: $spacing-1 * 10;
      }
    }
    // width: 100%;
  }
}
