.brand-campaign-regitration {
    .input-container {
        width: 50%;
        // margin-left: 6px;
        @include respond(phone) {
            width: 100%;
        }
    }
    .title-details {
        font-size: $txt-16;
        color: var(--primary-color);
        font-weight: bold;
    }
    
}

.brand-form {   
    @include respond(phone) {
        margin-top: 20px;
    }

}


