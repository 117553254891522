.topbar {
  background: var(--navbar-background);
  padding: 15px;

  .menu-button {
    padding: 5px;
    margin-right: 10px;
    color: var(--secondary-color);
    font-size: $txt-24;
  }
}
