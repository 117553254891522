// .bg-primary:has(> .MuiRadio-colorSecondary.Mui-checked) {
//   background-color: var(--primary-color);
// }
.sales-portal-manage {
  max-width: $desktop-breakpoint;
  margin: 0 auto;
  .MuiTextField-root {
    margin-top: $even-spacing * 2;
    margin-bottom: $even-spacing * 2;
  }

  .MuiStepIcon {
    // &-root {
    //     fill: $step-fill;
    // }
    &-active {
      fill: var(--primary-color);
    }

    &-completed {
      fill: var(--primary-color);
      opacity: 0.7;
    }
  }

  .MuiStepLabel-label {
    // color: $step-fill;
    $self: ".MuiStepLabel";

    &#{$self}-active {
      font-size: $txt-20;
      // color: black;
    }
  }

  section {
    margin-top: 16px;
    background-color: #fff;
    padding: $even-spacing * 6 $even-spacing * 6 $even-spacing * 4 $even-spacing * 6;
    border-radius: 10px;
    box-shadow: $box-shadow1;
    transition: box-shadow 0.3s ease-out;

    @include respond(phone) {
      overflow: auto;
      width: 300px;
    }

    @include respond(xs-phone) {
      width: 250px;
    }

    &:hover {
      box-shadow: $box-shadow1-hover;
    }
  }

  h1,
  h2,
  h3,
  h4 {
    overflow-wrap: break-word;
    word-break: break-word;
    margin-bottom: $even-spacing * 2;
  }

  h2 {
    color: var(--primary-text-color);
  }

  .next-btn {
    justify-content: flex-end;
  }

  .stepper {
    background: transparent;
    padding-left: 0px;
  }

  .subbutton {
    padding: 5px;
    margin: 10px 20px;
    border: 1px solid var(--primary-color);
    border-radius: 10px;
    width: 230px;
    padding-left: 20px;

    &.subbutton--checked {
      background: var(--primary-color);
      color: var(--solids-white);
    }
  }

  .radio-display-value {
    text-align: center;
    font-size: $txt-12;
    text-transform: uppercase;
  }

  .oldsub-menulist {
    position: relative;
    border: 1px solid var(--primary-color);
    border-radius: 5px;
  }

  .key-value-table {
    &__head-key {
      text-align: left;
      padding: 10px;
      border-bottom: 1px solid var(--navbar-background);
      font-weight: bold;
      color: var(--muted-text-color);
    }

    &__head-oldvalues {
      padding: 10px;
      border-bottom: 1px solid var(--navbar-background);
      font-weight: bold;
      color: var(--muted-text-color);
    }
    &__head-currentvalues {
      padding: 10px;
      border-bottom: 1px solid var(--navbar-background);
      font-weight: bold;
      color: var(--muted-text-color);
    }

    &__keys {
      width: 50%;
      padding: 10px;
    }

    &__keys-bg {
      background: var(--surface-color2);
      border-radius: 5px;
    }
    @include respond(phone) {
      width: 300px;
    }
  }

  .plans-table-container {
    .plans-table {
      max-width: $tab-land-breakpoint;
      margin: 0 auto;
    }
    @include respond(phone) {
      overflow: auto;
      .plans-table {
        min-width: 700px;
      }
    }
  }

  .step4 {
    width: 100%;
    // background-color: var(--primary-color);
    color: var(--solids-white);
    border-radius: 15px;
    &__head {
      tr th:last-child {
        color: var(--primary-color);
        text-align: center;
        padding: 16px 0px;
        font-size: $txt-20;
        font-weight: bold;
      }
    }
    &__body {
      position: relative;
      // background-color: var(--primary-color);
      &:before {
        content: "";
        position: absolute;
        width: calc(100% + 6px);
        height: calc(100% + 12px);
        top: -6px;
        left: 0;
        background-color: var(--primary-color);
        color: var(--solids-white);
        border-radius: 15px;
        z-index: 1;
      }
      * {
        position: relative;
        z-index: 3;
      }

      tr {
        $int-border-radius: 10px;
        td:nth-last-child(2) {
          background-color: var(--solids-white);
        }
        td:last-child {
          background-color: var(--solids-white);
        }
        &:first-child {
          td:nth-last-child(2) {
            border-top-left-radius: $int-border-radius;
          }
          td:last-child {
            border-top-right-radius: $int-border-radius;
          }
        }
        &:last-child {
          height: 12px;
          td:nth-last-child(2) {
            border-bottom-left-radius: $int-border-radius;
          }
          td:last-child {
            border-bottom-right-radius: $int-border-radius;
          }
        }
        &.totals-row {
          background-color: #d3d1d155;
          td:first-child {
            margin-left: 300px;
            padding: 10px;
            float: right;
            margin-right: 40px;
            font-weight: bold;
            @include respond(phone) {
              margin-left: 200px;
            }
          }
          td:last-child,
          td:nth-last-child(2) {
            font-weight: bold;
            text-align: center;
            margin: 2px;
            color: black;
            background-color: #efefefdd;
          }
        }
        .plan-fees-column {
          text-align: center;
          margin: 2px;
          background-color: #fff;
          color: black;
          width: 25%;
          // @include respond(phone) {
          //   min-width:fit-content;
          // }
        }
        .fees-type-column {
          padding: 10px;
          margin-left: 20px;
          float: left;
          // @include respond(phone) {
          //   max-width: 280px;
          // }
        }
        .fees-header-column {
          padding: 14px 10px;
          margin-left: 2px;
          float: left;
          font-size: $txt-14;
          font-weight: bold;
          // @include respond(phone) {
          //   max-width: 280px;
          // }
        }
      }

      // .border-right {
      //   border-right: 4px solid var(--primary-color);
      // }
    }
    &__footer {
      .month-total {
        text-align: center;
        font-size: $txt-14;
        font-weight: bold;
        padding: 10px 0px;
        color: var(--muted-text-color);
      }

      .total-amount {
        text-align: center;
        color: var(--solids-black);
        font-size: $txt-20;
        font-weight: bold;
        &::first-letter {
          color: var(--muted-text-color);
        }
      }
    }
    @include respond(phone) {
      min-width: max-content;
    }
  }

  .value-groups {
    width: 100%;
    min-width: 500px;
    @include respond(phone) {
      padding-right: $even-spacing * 6;
    }
  }

  // .st4table {
  //   width: 100%;
  // }

  // .st4td1 {
  //   width: 50%;
  // }

  // .st4td2 {
  //   // width: 50%;
  //   text-align: center;
  //   padding: 16px 0px;
  //   font-size: $txt-20;
  //   font-weight: bold;
  //   // color: var(--primary-color);
  // }

  // .totals-table {
  //   @include respond(phone) {
  //     width: 600px !important;
  //   }
  // }

  .ql-editor {
    min-height: 100px;
  }

  .ql-toolbar {
    background: var(--surface-color2);
  }
}

.warning-dialog {
  border: 1px solid var(--primary-color);
  &__head {
    display: flex;
    background: var(--danger-red-font);
  }
  &__similar-instances {
    padding: 2px;
    margin-left: 5px;
    color: var(--navbar-text-color);
  }
  &__card {
    padding: 7px;
    margin: 2px;
  }

  &__existing-record {
    color: var(--danger-red-font);
    margin: 10px;
    text-decoration: underline;
  }

  &__restrict-note {
    padding: 2px;
    font-size: $txt-14;
    color: var(--primary-text-color);
  }
  // .update-substate {
  //   color: var(--primary-color);
  // }

  // .stay-at-substate {
  //   color: var(--danger-red-font);
  // }
}
