.navbar {
  height: 100%;
  position: relative;
  background: var(--navbar-background);
  color: var(--navbar-text-color);
  width: $desktop-nav-bar-width;
  font-size: 14px;
  @include transition-default;

  &.collapsed {
    width: $desktop-nav-bar-collapsed-width;
  }

  a {
    text-decoration: none;
  }

  .brand {
    margin: 15px 0px;
  }

  .collapse-btn {
    position: absolute;
    top: 55px;
    right: -9px;
    z-index: 144;
    padding: 3px;
    min-width: auto;
    border-radius: 50%;
  }

  &-content {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    // border-top-right-radius: 15px;
    // border-bottom-right-radius: 15px;
  }

  .bottom-area {
    padding: 15px 40px 10px 40px;
    display: grid;
    grid-template-rows: auto auto;
    row-gap: 10px;
    @include transition-default;
    .profile {
      position: relative;
      display: grid;
      grid-template:
        "p n"
        "p d";
      transition: inherit;
      &__edit-button {
        position: absolute;
        top: -10px;
        left: -10px;
        z-index: 3;
        background-color: var(--solids-white);
        border: 2px solid var(--navbar-background);
        transition: inherit;
      }
      &-image {
        width: 65px;
        height: 65px;
        border-radius: 50%;
        grid-area: p;
        position: relative;
        transition: inherit;
        &::after {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          // border-radius: 50%;
          background-color: var(--navbar-background);
          z-index: 1;
          // border-radius: inherit;
          // border: 2px solid var(--solids-white);
        }
        &::before {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          z-index: 2;
          background-repeat: no-repeat;
          background-size: 65px 65px;
        }
        &:-moz-broken {
          opacity: 0;
        }
      }
      &-name {
        grid-area: n;
        line-height: 10px;
        word-break: break-word;
        span {
          vertical-align: sub;
        }
      }
      &-dept {
        grid-area: d;
        line-height: 10px;
        word-break: break-word;
        span {
          font-size: $txt-12;
          vertical-align: super;
        }
      }
    }
    &.minimal {
      padding: 15px 5px 10px 5px;
      .profile {
        grid-template:
          "p p"
          "n n"
          "d d";
        &__edit-button {
          top: -10px;
          left: 0px;
        }
        &-image {
          left: 12px;
          width: 50px;
          height: 50px;
          font-size: $txt-10;
          &::before {
            background-size: 50px 50px;
          }
        }
        &-name {
          text-align: center;
          font-size: $txt-12;
          line-height: 8px;
        }
        &-dept {
          text-align: center;
          line-height: 8px;
          span {
            font-size: $txt-10;
            vertical-align: baseline;
          }
        }
      }
    }
  }
}

.highlight-container,
.expanded-container {
  background: rgba(0, 88, 255, 0.5);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  margin-left: 4px;
}
.prod-legalchat {
  .highlight-container,
  .expanded-container {
    background: rgba(223, 176, 7, 0.5);
  }
}
.expanded-container {
  & > .navigation-group {
    .anchor {
      transform: rotate(90deg);
    }
  }
}
.navigation-group {
  cursor: pointer;
  .anchor {
    margin-right: 10px;
    @include transition-default;
  }
}

.nav-group-container {
  margin: {
    top: 5px;
    bottom: 5px;
  }

  padding: 10px {
    left: 20px;
    right: 0;
  }

  // .sub-route {
  //   margin: {
  //     left: 5px;
  //   }
  // }
  .avatar-icon {
    background-color: var(--navbar-text-color);
    color: var(--navbar-background);
    font-size: $txt-14;
    font-weight: 600;
  }
  .navigation-text {
    font-weight: 600;
    color: var(--navbar-text-color);
  }
  svg {
    // font-weight: 600;
    color: var(--navbar-text-color);
  }
  a {
    text-decoration: none;
  }
  @include transition-default;
}

.pop-over-transform {
  // padding-right: 20px;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  margin-right: 4px;
}

.navigation-content {
  display: block;
  .active,
  .highlight-font {
    .navigation-text {
      color: var(--secondary-color) !important;
    }
    .avatar-icon {
      background-color: var(--secondary-color) !important;
    }
    svg {
      color: var(--secondary-color) !important;
    }
  }
}
