.ui-filepicker {
    .custom-file-upload {
        border: 2px dashed var(--surface-color2);
        display: inline-block;
        padding: 30px;
        cursor: pointer;
        width: 100%;
        text-align: center;
        color: black;
        border-radius: 15px;
        &.custom-file-upload:hover {
            border: 2px dashed black;
        }

        &.fileborder {
            border: 2px dashed var(--primary-color) !important;
            color: var(--primary-color);
        }
        input[type="file"] {
            display: none;
        }
    }
}
// input[type="file"] {
//     display: none;
// }

// ::-webkit-file-upload-button {
//     border: 2px dashed blue;
//     display: inline-block;
//     padding: 30px;
//     cursor: pointer;
//     width: 100%;
//     text-align: center;
//     color: blue;
//     border-radius: 5px;
// }
