[class^="icon-"],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "icomoon" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-actionDashboard:before {
    content: "\e900";
    color: #5b95cf;
}
.icon-americanExpress:before {
    content: "\e901";
    color: #5b95cf;
}
.icon-appointment:before {
    content: "\e902";
    color: #5b95cf;
}
.icon-bill-overview:before {
    content: "\e903";
    color: #5b95cf;
}
.icon-blankCard:before {
    content: "\e904";
    color: #5b95cf;
}
.icon-callService:before {
    content: "\e905";
    color: #5b95cf;
}
.icon-callTransfer:before {
    content: "\e906";
    color: #5b95cf;
}
.icon-chatsIcon:before {
    content: "\e907";
    color: #5b95cf;
}
.icon-chat-transcript:before {
    content: "\e908";
    color: #5b95cf;
}
.icon-cirrus:before {
    content: "\e909";
    color: #5b95cf;
}
.icon-contentMarketingAndBlog:before {
    content: "\e90a";
    color: #5b95cf;
}
.icon-cross-bold:before {
    content: "\e90b";
    color: #5b95cf;
}
.icon-cross-thin:before {
    content: "\e90c";
    color: #5b95cf;
}
.icon-delete:before {
    content: "\e90d";
    color: #5b95cf;
}
.icon-discoverCard:before {
    content: "\e90e";
    color: #5b95cf;
}
.icon-dualBilling:before {
    content: "\e90f";
    color: #5b95cf;
}
.icon-edit:before {
    content: "\e910";
    color: #5b95cf;
}
.icon-excel-download:before {
    content: "\e911";
    color: #5b95cf;
}
.icon-excel-generate:before {
    content: "\e912";
    color: #5b95cf;
}
.icon-facebookChat:before {
    content: "\e913";
    color: #5b95cf;
}
.icon-facebookMarketplace:before {
    content: "\e914";
    color: #5b95cf;
}
.icon-googleAdsPerClick .path1:before {
    content: "\e915";
    color: rgb(91, 149, 207);
}
.icon-googleAdsPerClick .path2:before {
    content: "\e916";
    margin-left: -1em;
    color: rgb(160, 237, 131);
}
.icon-googleAdsPerClick .path3:before {
    content: "\e917";
    margin-left: -1em;
    color: rgb(91, 149, 207);
}
.icon-googleAdsPerClick .path4:before {
    content: "\e918";
    margin-left: -1em;
    color: rgb(91, 149, 207);
}
.icon-group:before {
    content: "\e919";
    color: #5b95cf;
}
.icon-groupHead:before {
    content: "\e91a";
    color: #5b95cf;
}
.icon-leadIcon:before {
    content: "\e91b";
    color: #5b95cf;
}
.icon-leadToRing:before {
    content: "\e91c";
    color: #5b95cf;
}
.icon-maestro:before {
    content: "\e91d";
    color: #5b95cf;
}
.icon-masterCard:before {
    content: "\e91e";
    color: #5b95cf;
}
.icon-paypal:before {
    content: "\e91f";
    color: #5b95cf;
}
.icon-performanceManager:before {
    content: "\e920";
    color: #5b95cf;
}
.icon-progress-bar-icon-boat:before {
    content: "\e921";
    color: #5b95cf;
}
.icon-progress-bar-icon-car:before {
    content: "\e922";
    color: #5b95cf;
}
.icon-progress-bar-icon-legal:before {
    content: "\e923";
    color: #5b95cf;
}
.icon-progress-bar-icon-rv:before {
    content: "\e924";
    color: #5b95cf;
}
.icon-progress-bar-icon-sp:before {
    content: "\e925";
    color: #5b95cf;
}
.icon-refresh:before {
    content: "\e926";
    color: #5b95cf;
}
.icon-reseller:before {
    content: "\e927";
    color: #5b95cf;
}
.icon-reseller-head:before {
    content: "\e928";
    color: #5b95cf;
}
.icon-seoWebsite:before {
    content: "\e929";
    color: #5b95cf;
}
.icon-settings:before {
    content: "\e92a";
    color: #5b95cf;
}
.icon-smsChat:before {
    content: "\e92b";
    color: #5b95cf;
}
.icon-submit-lead:before {
    content: "\e92c";
    color: #5b95cf;
}
.icon-tick:before {
    content: "\e92d";
    color: #5b95cf;
}
.icon-videoChat:before {
    content: "\e92e";
    color: #5b95cf;
}
.icon-visa:before {
    content: "\e92f";
    color: #5b95cf;
}
.icon-webChat:before {
    content: "\e930";
    color: #5b95cf;
}
.icon-websiteIcon:before {
    content: "\e931";
    color: #5b95cf;
}
