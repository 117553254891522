.reports-container {
    .title-area {
        font-size: 1rem;
        color: var(--primary-text-color);
        .title-dates {
            font-weight: 600;
        }
    }
    .report-area {
        width: 100%;
    }
    .reports-header {
        .refresh-overview {
            img {
                width: 20px;
                height: 20px;

                &:active {
                    animation: rightSpinQuarter 0.1s linear forwards;
                }
            }
        }
    }
}
