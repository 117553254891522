.rounded-checkbox {
  position: relative;
  cursor: pointer;
  input[type="checkbox"] {
    visibility: hidden;
  }

  input[type="checkbox"][disabled] {
    & + label {
      cursor: not-allowed;
      background-color: var(--muted-text-color2);
    }
  }

  input[type="checkbox"]:checked {
    & + label {
      &.label--primary {
        background-color: var(--secondary-color);
        border-color: var(--secondary-color);
      }
      &.label--secondary {
        background-color: var(--primary-color);
        border-color: var(--primary-color);
      }
    }
  }

  input[type="checkbox"]:checked + label:after {
    opacity: 1;
  }

  label {
    border-radius: 5px;
    cursor: pointer;
    height: 25px;
    left: 0;
    position: absolute;
    top: 0;
    width: 25px;
    user-select: none;
  }

  .label--primary {
    // background-color: var(--muted-text-color2);
    border: 1px solid var(--muted-text-color2);
  }

  label:after {
    border: 2px solid rgb(255, 255, 255);
    border-top: none;
    border-right: none;
    content: "";
    height: 25%;
    left: 20%;
    opacity: 0;
    position: absolute;
    top: 30%;
    transform: rotate(-45deg);
    width: 55%;
    user-select: none;
  }
}
