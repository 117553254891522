@for $i from 0 through 8 {
    .l-p-#{$i} {
        padding-left: $i * $even-spacing !important;
    }

    .r-p-#{$i} {
        padding-right: $i * $even-spacing !important;
    }

    .t-p-#{$i} {
        padding-top: $i * $even-spacing !important;
    }

    .b-p-#{$i} {
        padding-bottom: $i * $even-spacing !important;
    }

    .hm-p-#{$i} {
        padding: 0px $i * $even-spacing;
    }

    .vm-p-#{$i} {
        padding: $i * $even-spacing 0px;
    }
}

@for $i from 0 through 8 {
    .l-m-#{$i} {
        margin-left: $i * $even-spacing !important;
    }

    .r-m-#{$i} {
        margin-right: $i * $even-spacing !important;
    }

    .t-m-#{$i} {
        margin-top: $i * $even-spacing !important;
    }

    .b-m-#{$i} {
        margin-bottom: $i * $even-spacing !important;
    }

    .hm-m-#{$i} {
        margin: 0px $i * $even-spacing;
    }

    .vm-m-#{$i} {
        margin: $i * $even-spacing 0px;
    }
}

@include respond(tab-port) {
    @for $i from 0 through 8 {
        .p-l-m-#{$i} {
            margin-left: $i * $even-spacing;
        }

        .p-r-m-#{$i} {
            margin-right: $i * $even-spacing;
        }

        .p-t-m-#{$i} {
            margin-top: $i * $even-spacing;
        }

        .p-b-m-#{$i} {
            margin-bottom: $i * $even-spacing;
        }

        .p-hm-m-#{$i} {
            margin: 0px $i * $even-spacing;
        }

        .p-vm-m-#{$i} {
            margin: $i * $even-spacing 0px;
        }
    }
}

.l-m-phone-inv {
    @include respond(tab-port) {
        margin-left: 0px !important;
    }
}

.r-m-phone-inv {
    @include respond(tab-port) {
        margin-right: 0px !important;
    }
}

.t-m-phone-inv {
    @include respond(tab-port) {
        margin-top: 0px !important;
    }
}

.hm-m-phone-inv {
    @include respond(tab-port) {
        margin: $even-spacing 0px;
    }
}

.hm-m-auto {
    margin: 0px auto;
}

.w-100 {
    width: 100%;
}

.w-50 {
    width: 50%;
}

.max-w-small-desktop {
    max-width: $small-desktop-breakpoint;
}

.max-w-tab-port {
    max-width: $tab-port-breakpoint;
}