.process-payment {
  background: var(--surface-color);
  &__loader {
    width: 200px;
    height: 200px;
    display: inline-block;
    overflow: hidden;
  }
  &__label {
    font-size: $txt-18;
    color: var(--primary-text-color);
    text-align: center;
    margin-top: $spacing-1 * 10;
  }
  .inside div {
    position: absolute;
    animation: payment-process-animation 1s linear infinite;
    width: 160px;
    height: 160px;
    top: 20px;
    left: 20px;
    border-radius: 50%;
    box-shadow: 0 4px 0 0 var(--primary-color);
    transform-origin: 80px 82px;
  }
  .inside {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }
  .inside div {
    box-sizing: content-box;
  }
  .ticket {
    min-width: 350px;
    min-height: 173px;
    border-radius: 3px;
    background: var(--solids-white);
    padding: $spacing-1 * 10;
    filter: drop-shadow(0 5px 5px rgba(#000, 0.35));
  }

  &__success {
    .success-tick {
      height: 80px;
      width: 80px;
      padding: $spacing-1 * 8;
      border-radius: 50%;
      background: linear-gradient(
        130deg,
        rgba(20, 202, 173, 1) 35%,
        rgba(38, 214, 150, 1) 100%
      );
    }
    .success-label {
      font-size: $txt-24;
      font-weight: 600;
      background: rgb(20, 202, 173);
      background: linear-gradient(
        130deg,
        rgba(20, 202, 173, 1) 35%,
        rgba(38, 214, 150, 1) 100%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin: $spacing-1 * 10 auto $spacing-1 * 5;
    }
  }
  &__failed {
    .failure-cross {
      height: 80px;
      width: 80px;
      padding: $spacing-1 * 8;
      border-radius: 50%;
      background: rgb(255, 39, 123);
      background: linear-gradient(
        130deg,
        rgba(255, 39, 123, 1) 35%,
        rgba(255, 5, 21, 1) 100%
      );
    }
    .failure-label {
      font-size: $txt-24;
      font-weight: 600;
      background: rgb(255, 39, 123);
      background: linear-gradient(
        130deg,
        rgba(255, 39, 123, 1) 35%,
        rgba(255, 5, 21, 1) 100%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin: $spacing-1 * 10 auto $spacing-1 * 5;
    }
  }

  &__button {
    margin: $spacing-1 * 10 auto;
  }
  .dashed-seperator {
    height: 1px;
    width: 100%;
    border: 1px dashed #cad5e4;
  }
  .info {
    color: var(--muted-text-color);
    margin: $spacing-1 * 5 auto;
  }
  .amount {
    justify-content: space-between;
  }
}
@keyframes payment-process-animation {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
