.manage-saas--wrapper {
  p {
    color: var(--primary-text-color);
    margin: 0;
  }
  .go-to-dash {
    height: 30px;
    width: 30px;
  }
  .go-to-settings {
    height: 25px;
    width: 25px;
  }
  .subscriber-table {
    height: calc(100vh - 130px);
    @include respond(tab-port) {
      height: calc(100vh - 270px);
    }
  }
  .small-icon {
    height: 16px;
    width: 16px;
    svg {
      height: 100%;
      width: 100%;
    }
  }

  .feature-icon {
    cursor: pointer;
    height: 20px;
    width: 25px;
    margin: $spacing-1 * 2 $spacing-1 * 4;
    img {
      width: 100%;
    }
  }
  .subscriber-info {
    .name-status {
      p {
        color: var(--primary-text-color);
        font-size: $txt-16;
        font-weight: 600;
        // letter-spacing: $spacing-1;
        margin: 0 {
          left: $spacing-1 * 4;
          bottom: $spacing-1 * 2;
        }
      }
      .status-flag {
        height: 18px;
        width: 18px;
        cursor: pointer;
        svg {
          height: 100%;
          width: 100%;
        }
      }
    }
    .location {
      margin: 0 {
        left: $spacing-1 * 12;
      }
      font-size: $txt-14;
      color: var(--muted-text-color);
      // font-style: italic;
    }
  }

  .subscription-info {
    .credit-card {
      // height: 20px;
      // width: 30px;
      margin: 0 {
        left: $spacing-1 * 5;
      }
      // img {
      //   height: 100%;
      //   width: 100%;
      // }
    }
  }

  .timeline-wrapper {
    height: 65px;
    position: relative;
    justify-content: space-around;
    .timeline-track {
      position: absolute;
      left: 7px;
      height: 100%;
      width: 2px;

      background: #d2dbe6;
    }
    .timeline-row {
      width: 100px;
    }
    .timeline-node {
      position: relative;
      z-index: 1;
      margin-right: $spacing-1 * 3;
    }
    .timeline-date {
      font-size: $txt-14;
      font-weight: 600;
      color: #7a7a7a;
    }
  }

  .group-info {
    p {
      font-size: $txt-14;
      color: var(--primary-text-color);
    }
  }
}

.manage-saas_header--wrapper {
  .filter-controls {
    justify-content: flex-end;

    @include respond(small-desktop) {
      margin-left: $spacing-1 * 5;
    }
    @include respond(tab-port) {
      margin-top: $spacing-1 * 5;
      margin-left: 0;
    }

    .filter-wrapper {
      justify-content: flex-end;
    }
  }

  .search-field {
    margin-right: $spacing-1 * 4;
    width: 300px;
    @include respond(tab-port) {
      width: initial;
    }
  }
}
.manage-saas--search-suggestions {
  max-height: 500px;
  min-width: 300px;
  @include respond(tab-port) {
    min-width: calc(100vw - 84px);
    max-height: calc(100vh - 300px);
  }
  ul {
    padding: 0;
  }
  .group-name {
    text-transform: capitalize;
    background-color: #fff;
  }
  .options {
    padding: 0 $spacing-1 * 18;
  }
}

.badge {
  color: #fff;
  font-size: 11px;
  padding: 1px 5px;
  border-radius: 5px;
  background-color: #7a7a7a;
}
