#user-management-area {
    margin-right: auto;
    margin-left: auto;
    margin-bottom: $even-spacing * 4;
    padding: 0 $even-spacing * 2;
    max-width: $small-desktop-breakpoint;
}
.contacts-dialog {
    .grid-container {
        display: grid;

        grid-template-columns: 1fr 1fr;
        grid-column-gap: $even-spacing * 3;
        grid-row-gap: 4px;

        @include respond(phone) {
            grid-row-gap: 4px;
            grid-template-columns: 1fr;
        }
    }
}
