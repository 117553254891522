.blue-responsive-table {
    .visually-hidden {
        border: 0px;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0px;
        position: absolute;
        top: 20px;
        width: 1px;
    }
}

.b_table_container {
    overflow-x: auto;
}

.b_header {
    background-color: var(--primary-color);
    color: white;
}

.b_header th {
    border-bottom: 0px;
}

.b_header td:first-child {
    border-radius: 10px 0 0 0px;
}

.b_header td:last-child {
    border-radius: 0 10px 0px 0;
}

.b_table {
    border-radius: 13px;
    width: 50%;
    margin: 0 auto;
    border-collapse: collapse;
}

.b_table_full {
    width: 100%;
}

.b_table_sticky {
    .th {
        top: 0;
        position: sticky;
        z-index: 1;
    }
}

.b_body {
    background-color: white;
}