.l2r-setup-section {
    max-width: 600px;
    margin: 0 auto;
    margin-bottom: 50px;
    .info {
        color: var(--muted-text-color);
        margin: 0 auto;
    }
    .department-section {
        &__title {
            background-color: var(--primary-color);
            color: white;
            padding: 5px 10px;
            width: 100%;
            border-radius: 4px;
            margin: 10px 0px 5px 0px;
        }
    }
}
