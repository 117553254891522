.agreement-transcript {
    width: 100%;
    background-color: var(--surface-color);
    overflow: auto;
    height: 100%;
    padding: 1vh 0;
    .page {
        width: 70%;

        margin: auto;
        overflow-x: hidden;
        border-radius: 8px;
        box-shadow: 0 3px 2px rgba(var(--light-box-shadow), 0.2), 0 6px 6px rgba(var(--light-box-shadow), 0.2);
        @include respond(phone) {
            width: 95%;
        }
    }
    .header {
        background-color: var(--navbar-background);
        padding: 6px 16px;
    }
    .content-body {
        // width: 70%;
        // margin: auto;
        // overflow-x: hidden;
        // // border-radius: 8px;
        // // box-shadow: 0 3px 2px rgba(var(--light-box-shadow), 0.2), 0 6px 6px rgba(var(--light-box-shadow), 0.2);
        // @include respond(phone) {
        //     width: 95%;
        // }
        padding: 12px 40px;
        background-color: white;
    }
}
