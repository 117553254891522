.integrations-area {
    width: 100%;

    .card-area {
        margin: 10px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        row-gap: 10px;
        column-gap: 10px;

        @include respond(tab-port) {
            grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
            row-gap: 10px;
            column-gap: 10px;
        }

        @include respond(phone) {
            grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
            row-gap: 10px;
            column-gap: 10px;
        }
    }

    .card-container {
        // @include respond(phone) {
        //     height: 210px;
        // }
        height: 180px;
        box-shadow: $box-shadow1;

        #logo {
            margin: 2px 20px;
            width: 40px;
            height: 40px;
            display: flex;
        }

        .card-details {
            margin-top: 20px;
            display: flex;
        }

        .setup-desc {
            font-size: 11px;
        }

        .setup-btn {
            margin: 10px;
            align-self: flex-end;
        }

        .side-panel-details {
            padding: 0px;
        }

        .srch-btn {
            height: 20px;
        }
    }
}