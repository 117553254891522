%button {
  @extend %txt;
  padding: 10px;
  border-radius: 5px;
  margin: $spacing-1;
  cursor: pointer;
  outline: none;
  text-align: center;
  user-select: none;
  border: none;
  font-weight: 600;
  transition: all 0.3s;
}
.btn {
  @extend %button;
}
.btn-secondary {
  @extend %button;
  background: var(--surface-color2);
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  &:hover {
    background: var(--primary-color);
    color: var(--solids-white);
  }
}

.btn-primary {
  @extend %button;
  background: var(--primary-color);
  border: 1px solid transparent;
  color: var(--solids-white);
  &:hover {
    background: var(--primary-color);
    color: var(--solids-white);
  }
}

.btn-link {
  @extend %button;
  background: none;
  color: var(--primary-color);
  &:hover {
    background: var(--surface-color2);
  }
}

.btn-small {
  font-size: $txt-14;
}
.no-link-decor {
  text-decoration: none;
  &:hover {
    text-decoration: none !important;
  }
}

.btn--round {
  padding: {
    left: 30px;
    right: 30px;
  }
  border-radius: 20px;
}
