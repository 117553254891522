.failed-component {
    display: flex;
    flex-direction: column;
    .failed-data {
        margin: 0 auto;
        color: var(--muted-text-color);
        font-size: $txt-20;
    }
    img {
        margin: 0 auto;
    }
    &.small {
        img {
            max-width: 200px;
            // height: 135px;
        }
        .failed-data {
            font-size: $txt-16;
        }
    }
    &.medium {
        img {
            max-width: 400px;
            // height: 270px;
        }
        .failed-data {
            font-size: $txt-20;
        }
    }
    &.large {
        img {
            max-width: 600px;
            // height: 405px;
        }
        .failed-data {
            font-size: $txt-24;
            text-align: center;
        }
    }
}
