.role-permissions{
    &__header{
        font-size: $txt-16
    }
    &__role-list {
        & > div {
            span {
                font-size: $txt-14;
            }
        }
        
    }

}