.collapsible {
    $self: &;
    width: 100%;
    border: 1px solid rgb(153, 153, 153);
    border-radius: 8px;
    &-group {
        & .collapsible:not(:first-child) .collapsible-title {
            border-radius: 0px 0px 0px 0px;
        }
        & .collapsible:not(:last-child) .collapsible-body {
            border-radius: 0px 0px 0px 0px;
        }
    }
    #{$self}-title {
        @extend %txt;
        line-height: normal;
        font-size: $txt-16;
        font-weight: bold;
        padding: 16px 24px 14px;
        border-radius: $b-radius $b-radius 0px 0px;
        background: var(--greys-grey-60);
        color: var(--greys-grey-90);
        cursor: pointer;
        z-index: 1;
        @include transition-default;
        & i {
            transition: inherit;
            vertical-align: middle;
            position: relative;
            float: right;
        }
 
        &.active {
            color: var(--greys-grey-60);
            background: var(--greys-grey-90);
 
            & i {
                transform: rotate(90deg);
                color: var(--brand-primary);
            }
        }
    }
 
    #{$self}-body {
        background: var(--greys-grey-30);
        border-radius: 0px 0px $b-radius $b-radius;
        overflow-x: hidden;
        overflow-y: hidden;
        max-height: 1200px;
        width: 100%;
        height: 100%;
        @include transition-default;
        &.hide {
            height: 0%;
            max-height: 0px;
        }
    }
}