.agreement-dialog {
    &__content {
        padding: 8px 24px;
        @include respond(phone) {
            padding: 8px 12px !important;
        }
    }
    &__filetype {
        word-break: break-all;
    }
}
