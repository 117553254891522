.subscriber-d {
    width: 100%;

    .card {
        &-title {
            @extend %txt;
            color: var(--primary-text-color);
            font-size: $txt-16;
            font-weight: 500;
            margin-bottom: $even-spacing * 4;
        }

        &-body {
            box-shadow: $box-shadow1;
            background-color: white;
            padding: $even-spacing * 4;
            border-radius: 6px;

            &:hover {
                box-shadow: $box-shadow1-hover;
            }
        }

        @include respond(tab-port) {
            width: 100%;
        }
    }

    .info-pill {
        display: inline-block;

        img {
            width: 20px;
            height: 20px;
            margin-right: 5px;
        }

        div {
            font-weight: 500;
            display: inline-block;
            vertical-align: super;
            color: var(--muted-text-color2);

            span {
                font-weight: 600;
                color: var(--primary-color);
            }
        }
    }

    .refresh-graph-area {
        display: flex;
        justify-content: flex-end;
        padding: $even-spacing * 2 $spacing-1;

        img {
            width: 20px;
            height: 20px;

            &:active {
                animation: rightSpinQuarter 0.1s linear forwards;
            }
        }
    }

    .graph-title {
        @extend %txt;
        font-weight: 600;
        color: var(--primary-text-color);
        font-size: $txt-14;
    }

    canvas {
        width: 100% !important;
        height: 100% !important;
    }

    .counters {
        justify-content: space-around;
    }

    .progress-indicator-container {
        $self: ".progress-indicator";
        width: 100%;
        height: 20px;
        position: relative;
        @include transition-default;

        #{$self} {
            top: 0;
            left: 0;
            position: absolute;
            // width: 70%;
            height: inherit;
            border-radius: 50px;
            background: linear-gradient(122deg, var(--primary-color) 35%, var(--primary-color-light) 100%);
            transition: inherit;
        }

        &::before {
            content: "";
            top: 0;
            left: 0;
            position: absolute;
            width: 100%;
            height: inherit;
            border-radius: 50px;
            background: var(--surface-color2);
        }

        // &::after {
        //     content: "";
        //     border-top: 2px dashed white;
        //     position: absolute;
        //     top: 45%;
        //     width: 36%;
        // }
        .dashed-line {
            border-top: 2px dashed white;
            position: absolute;
            top: 45%;
            transition: inherit;
        }

        img {
            position: absolute;
            transition: inherit;
            height: 15px;
            top: 12%;
        }
    }

    .color-card {
        height: 180px;
        width: 17%; //180px;
        padding: 1%; // $even-spacing * 5;
        border-radius: 8px;
        color: var(--solids-white);
        text-align: center;
        position: relative;
        overflow: hidden;

        @media only screen and (max-width: $tab-port-breakpoint) {
            height: 100px;
        }

        .bg {
            position: absolute;
            right: -6%;
            opacity: 0.3;
            bottom: -0%;
            height: 81px;
        }

        .label {
            font-size: $txt-16;
        }

        .value {
            font-weight: 600;
            // font-size: $txt-24;
            font-size: 13px;
        }

        @include respond(tab-port) {
            width: 100%;
            margin-bottom: $spacing-1 * 4;

            &:last-child {
                margin-bottom: 0;
            }

            .bg {
                right: -2%;
            }
        }

        &.visitor {
            background: rgb(255, 67, 138);
            background: linear-gradient(120deg, rgba(255, 67, 138, 1) 35%, rgba(229, 1, 2, 1) 100%);
        }

        &.web-chat {
            background: rgb(249, 143, 25);
            background: linear-gradient(122deg, rgba(249, 143, 25, 1) 35%, rgba(231, 76, 0, 1) 100%);
        }

        &.text-chat {
            background: rgb(255, 118, 221);
            background: linear-gradient(122deg, rgba(255, 118, 221, 1) 35%, rgba(138, 0, 255, 1) 100%);
        }

        &.facebook-chat {
            background: rgb(0, 174, 255);
            background: linear-gradient(122deg, rgba(0, 174, 255, 1) 35%, rgba(45, 96, 255, 1) 100%);
        }

        &.live-operator {
            background: rgb(0, 222, 146);
            background: linear-gradient(122deg, rgba(0, 222, 146, 1) 35%, rgba(0, 144, 129, 1) 100%);
        }
    }

    .profile-completeness {

        &>div,
        &>button {
            margin-top: $spacing-1 * 4;
        }

        .percentage {
            font-size: $txt-28;
            color: var(--primary-color);
            font-weight: 600;
            margin-top: 0px;
        }

        .help-text {
            color: var(--primary-text-color);
            font-size: $txt-14;
            text-align: center;
        }

        height: 228px;
        justify-content: space-between;
    }

    .info-span {
        padding-left: 5px;
    }

    .card-error {
        margin-bottom: 10px;
        text-align: center;
        font-size: $txt-20;
        color: red;
    }

    // .table-text {
    //     padding: 14px 1%;
    //     font-size: 0.8rem;
    // }
    .card-half {
        width: 100%;
    }

    .appt-calendar {
        width: 50%;

        // margin: auto;
        // overflow-x: auto;
        @include respond(phone) {
            width: 100%;
        }
    }
}

.calendar {
    box-shadow: $box-shadow1-hover;
}

.card-padding-mobile {
    @include respond(phone) {
        padding-left: 28px !important;
    }
}


.chart-card {
    width: 50%;

    @include respond(tab-port) {
        width: 100%;
    }
}

.grid-container {
    display: grid;

    grid-template-columns: 4rem auto;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    // for mobile view
    @include respond(phone) {
      grid-row-gap: 10px;
      grid-template-columns: 4rem 9fr;
      position: relative;
      // right: 80px;
    }
  }

// .dashboard-card-holder{
//     @if $breakpoint == tab-land {
//          @media only screen and (max-width: $tab-land-breakpoint) {
//              @content;
//         }
//     }
// }