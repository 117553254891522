.page-wrapper {
  background: var(--surface-color);
  .page-header {
    padding: $spacing-1 * 5 {
      bottom: 0;
    }
    font-size: $txt-24;
    color: var(--primary-text-color);
    font-weight: 700;
    // letter-spacing: 1.2px;
    position:sticky;
    top: 0;
    background: var(--surface-color);
    z-index: 99;
  }
  .page-body {
    background-color: var(--surface-color);
    height: 100%;
    padding: $spacing-1 * 5 {
      bottom: 0;
    }
  }
  .page-footer {
    font-size: $txt-14;
    background-color: #fff;
    padding: $spacing-1 * 5 $spacing-1 * 3;
  }
}
