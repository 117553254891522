.responsive-table {
    min-width: unset !important;
    &__header {
        &.no-toolbar {
            .MuiTableCell-head {
                background-color: var(--primary-color);
                color: white;
                &:first-child {
                    border-top-left-radius: 8px;
                }
                &:last-child {
                    border-top-right-radius: 8px;
                }
            }
            $self: ".MuiTableSortLabel";
            #{$self}-root {
                @include transition-default;
                color: white;
                &:hover {
                    color: var(--secondary-color) !important;
                }
                &#{$self}-active {
                    color: white;
                    & #{$self}-icon {
                        transition: inherit;
                        color: white !important;
                    }
                    &:hover #{$self}-icon {
                        color: var(--secondary-color) !important;
                    }
                }
            }
        }
    }
    .visually-hidden {
        border: 0px;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0px;
        position: absolute;
        top: 20px;
        width: 1px;
    }
}
