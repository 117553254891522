%flex {
  display: flex;
}

.full-size {
  @extend %flex;
  flex-grow: 1;
  overflow: hidden;
}
.full-size-root {
  @extend %flex;
  flex-grow: 1;
  overflow: hidden auto;
  height: 100vh;
  // @include respond-height() {
  //   height: 120vh;
  // }
  // @include respond-height(420px) {
  //   height: 200vh;
  // }
}

.flex-column {
  @extend %flex;
  flex-direction: column;
}

.flex-row {
  @extend %flex;
  flex-direction: row;
}

.flex-xs-responsive {
  @include respond(xs-phone) {
    flex-direction: column;
  }
}
.flex-p-responsive {
  @include respond(phone) {
    flex-direction: column;
  }
}

.flex-responsive {
  $self: &;
  @extend %flex;
  flex-direction: row;
  &#{$self}-center {
    align-items: center;
  }
  @include respond(tab-port) {
    flex-direction: column;
    align-items: center;
    &#{$self}-center {
      justify-content: center;
    }
  }
}
.flex-responsive-breakline {
  @media only screen and (max-width: 1072px) {
    display: block !important;
  }
  $self: &;
  @extend %flex;
  flex-direction: row;
  &#{$self}-center {
    align-items: center;
  }
  @include respond(tab-port) {
    flex-direction: column;
    align-items: center;
    &#{$self}-center {
      justify-content: center;
    }
  }
}

// TODO: Refactor the name
.simple-flex-responsive {
  $self: &;
  @extend %flex;
  flex-direction: row;

  @include respond(tab-port) {
    flex-direction: column;
  }
}

.flex-responsive-reverse {
  $self: &;
  @extend %flex;
  flex-direction: column;

  @include respond(tab-port) {
    flex-direction: row;
  }
}

.flex-noshrink {
  flex-shrink: 0;
}

.flex-grow {
  flex-grow: 1;
}

@for $i from 1 through 8 {
  .flex-g-#{$i} {
    flex-grow: $i;
  }
  .flex-s-#{$i} {
    flex-shrink: $i;
  }
}

.flex-center {
  align-items: center;
}
.flex-end {
  align-items: flex-end;
}
.flex-start {
  align-items: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.space-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}

.flex-same {
  flex: 1 1 auto;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-basis-100 {
  flex-basis: 100%;
}
