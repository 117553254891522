.chat-transcript{
    width: 100%;
    background-color: var(--surface-color);
    overflow: auto;
    height: 100%;
    padding: 1vh 0;
    
    .page{
        width: 70%;
        margin: auto;
        overflow-x: hidden;
        border-radius: 8px;
        box-shadow: 0 3px 2px rgba(var(--light-box-shadow), 0.2), 0 6px 6px rgba(var(--light-box-shadow), 0.2);
        @include respond(phone) {
            width: 95%;
        }
        .header{
            background-color: var(--navbar-background);
            padding: 6px 16px;

            // button {
            //     background-color: inherit;
            //     color: white;
            //     padding: 12px;
            //     border: 1px solid #0c4769;
            //     border-radius: 8px;

            //     &:hover {
            //         background-color: #b7c1c723;
            //     }
            //     &:active {
            //         border: 1px solid #000e1688;
            //     }
            // }
        }
        .subhead {
            background-color: #fcf1b8;
            color: #6f602a;
            text-align: center;
            padding: 6px;
            font-size: 0.8rem;
            @include respond(phone) {
                font-size: 0.7rem;
            }
        }
        .content {
            background-color: var(--solids-white);
            color: var(--primary-text-color);
            padding: 22px;
            
            .content-headings {
                font-weight: bold;
                font-size: 1rem;
                border-bottom: 1px solid #dddddd;
                padding: 16px 0px;
            }
            .content-body {
                padding: 12px 0px;
                .content-data {
                    padding: 6px 0px;

                    .value {
                        font-weight: bold;
                    }
                    a:link {
                        text-decoration: none;
                        color: var(--muted-text-color);
                    }
                    a:hover {
                        text-decoration: underline;
                    }
                }
            }
        }

    }
}