.responsive-dialog {
  .title {
    font-size: $txt-20 !important;
    margin: 0;
    padding: 0;
  }
  .close-btn {
    position: absolute;
    right: $spacing-1;
    top: $spacing-1;
  }
}
