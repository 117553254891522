.dt {
  &-table {
    display: table;
  }
  &-row {
    display: table-row;
  }
  &-col {
    display: table-column;
  }
  &-cell {
    display: table-cell;
  }
}
