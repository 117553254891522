.op-per-collapsible {
    border-left: 1px solid var(--surface-color2) !important;
    border-right: 1px solid var(--surface-color2) !important;
    border-bottom: 1px solid var(--surface-color2) !important;
    &__title {
        border-radius: 0px !important;
        min-height: auto !important;
        padding: 0px !important;
        &:hover {
            background-color: var(--surface-color2);
            // .collapsible-grid-item {
            // }
        }
        &.active {
            background-color: var(--surface-color-yellow) !important;
            // .collapsible-grid-item {
            // }
        }
        .name {
            font-size: $txt-16;
            text-align: left;
        }
        .group-name {
            font-size: $txt-12;
            font-weight: normal;
            text-align: left;
        }
        .title-chip {
            font-size: $txt-10;
            font-weight: bold;
            background-color: var(--solids-grey-light);
            border-radius: 4px;
            padding: 1px 4px;
        }
        .value-chip {
            font-weight: normal;
            font-size: $txt-10;
            color: var(--muted-text-color);
        }
        .collapsible-grid-item {
            padding: 14px 4px;
            // background-color: white;
            color: black;
            font-size: 0.95rem;
            font-weight: normal;
            text-align: center;
            word-break: break-word;
            @include transition-default;
            & i {
                transition: inherit;
                vertical-align: middle;
                position: relative;
                float: right;
            }
        }
    }
    &__body {
        background-color: var(--surface-color2) !important;
    }
    @include respond(tab-land) {
        border-top: 1px solid var(--surface-color2) !important;
    }
}
.table-grid-container {
    display: grid;
    grid-template-columns:
        minmax(100px, 2fr) minmax(100px, 2fr) minmax(50px, 1fr) minmax(50px, 1fr) minmax(50px, 1fr) minmax(50px, 1fr)
        minmax(50px, 1fr) minmax(50px, 1fr) minmax(50px, 1fr) minmax(50px, 1fr) minmax(60px, 1fr) minmax(50px, 1fr);
    // grid-template-columns: 2fr 2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    // gap: 1px 1px;
    // padding: 2px;
    // background-color: rgb(132, 155, 179);
    background-color: var(--primary-color);
    // border-top-left-radius: 8px;
    // border-top-right-radius: 8px;
    background-color: transparent;
    // padding: 0;

    .table-grid-header {
        padding: 10px 5px;
        background-color: var(--primary-color);
        color: white;
        text-align: center;
        word-break: break-word;
    }

    .rounded-corner-left {
        border-top-left-radius: 8px;
    }
    .rounded-corner-right {
        border-top-right-radius: 8px;
    }
}
