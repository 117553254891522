@mixin respond($breakpoint) {
    @if $breakpoint == xs-phone {
        @media only screen and (max-width: $xs-phone-breakpoint) {
            @content;
        }
    }
    @if $breakpoint == phone {
        @media only screen and (max-width: $phone-breakpoint) {
            @content;
        }
    }
    @if $breakpoint == tab-port {
        @media only screen and (max-width: $tab-port-breakpoint) {
            @content;
        }
    }
    @if $breakpoint == tab-land {
        @media only screen and (max-width: $tab-land-breakpoint) {
            @content;
        }
    }
    @if $breakpoint == small-desktop {
        @media only screen and (max-width: $small-desktop-breakpoint) {
            @content;
        }
    }
    @if $breakpoint == desktop {
        @media only screen and (max-width: $desktop-breakpoint) {
            @content;
        }
    }
    @if $breakpoint == big-desktop {
        @media only screen and (max-width: $big-desktop-breakpoint) {
            @content;
        }
    }
}

@mixin transition-default {
    transition: all 0.3s ease;
}

@mixin hide-scroll-for($elem) {
    #{$elem} {
        scrollbar-color: transparent transparent;
    }
    #{$elem}::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: transparent;
        box-shadow: inset 0 0 6px transparent;
        outline: 1px solid transparent;
    }

    #{$elem}::-webkit-scrollbar {
        width: 0px;
        background: transparent;
    }

    #{$elem}::-webkit-scrollbar-track {
        border-radius: 0px;
        background-color: transparent;
    }

    #{$elem}::-webkit-scrollbar-corner {
        border-radius: 0px;
        background-color: transparent;
    }
}

@mixin respond-height($height: 650px) {
    @media only screen and (max-height: $height) {
        @content;
    }
}

@mixin respond-small-screen() {
    @media only screen and (max-height: 361px) {
        @content;
    }
}
