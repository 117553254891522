#sites-settings-area {
    margin-right: auto;
    margin-left: auto;
    margin-bottom: $even-spacing * 4;
    padding: 0 $even-spacing * 2;
    max-width: $small-desktop-breakpoint;
}
.sites-panel {
    &__header {
        // width: 100%;
        // height: 60px;
        padding-top: 15px;
        padding-bottom: 15px;
        background-color: var(--primary-color-dark);
        color: var(--surface-color);
        font-size: $txt-18;
    }
}
