.reputation-management-reviews {
    // margin-right: auto;
    // margin-left: auto;
    // margin-bottom: $even-spacing * 4;
    // padding: 0 $even-spacing * 2;
    max-width: $tab-land-breakpoint;
    // min-height: 100vh;
    margin: 0 auto;
    .review-accordian {
        $self: ".review-accordian ";
        &__header {
            padding: 16px 0px;
            // margin: 4px 4px;

            &__header-data {
                display: flex;
                justify-content: space-between;
                align-items: center;

                &__name {
                    font-size: $txt-20;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    word-break: break-all;
                }
                &__time {
                    font-size: $txt-12;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    // color: var(--muted-text-color);
                }
                // &__status {
                //     // font-size: $txt-14;
                //     display: flex;
                //     flex-direction: row;
                //     align-items: center;
                // }
                &__ratings {
                    text-align: right;
                    display: flex;
                }
            }
            &__review {
                font-size: $txt-14;
                margin-left: 50px;
                @include respond(phone) {
                    text-align: justify;
                }
            }
            &__replyReview {
                width: 50%;
                margin-left: 50px;
                // height: 10px;
                @include respond(phone) {
                    width: 80%;
                    margin-left: 47px;
                }
            }
        }
        &__body {
            // padding: 2px 2px;
            // margin: 2px 2px;
            margin-left: 50px;
            @include respond(phone) {
                margin-left: 10px;
            }

            .small-btn {
                font-size: $txt-12;
                padding: 2px 3px;
                min-width: 45px;
            }

            &__body-conatiner {
                display: flex;
                justify-content: space-between;
                align-items: center;
                // word-break: break-all;

                &__replyer-name {
                    font-size: $txt-16;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    word-break: break-all;
                }
                &__replyer-time {
                    font-size: $txt-10;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    color: var(--muted-text-color);
                }
                .tag {
                    font-size: $txt-10;
                    height: 20px;
                }
                &__replyer-expand-btn {
                    font-size: $txt-12;
                }
                &__status {
                    // font-size: $txt-14;
                    display: flex;
                    // flex-direction: row;
                    align-items: center;
                }
                &__onRefresh {
                    button:active {
                        animation: rightSpinQuarter 0.1s linear forwards;
                    }
                    img {
                        width: 20px;
                        height: 20px;
                        &:active {
                            animation: rightSpinQuarter 0.1s linear forwards;
                        }
                    }
                }
                &__menu {
                    text-align: right;
                    @include respond(phone) {
                        // position: relative;
                        // top: 17px;
                    }
                }
            }
            &__editReply {
                font-size: $txt-12;
                margin-left: 50px;
                width: 100%;
                @include respond(phone) {
                    text-align: justify;
                }
            }
            &__commentReply {
                font-size: $txt-12;
                margin-left: 50px;
                width: 100%;
                @include respond(phone) {
                    text-align: justify;
                }
            }
        }
    }
    .reputation-tabs {
        box-shadow: 0 2px 5px rgba(var(--light-box-shadow), 0.1), 0 10px 20px rgba(var(--light-box-shadow), 0.1);
    }
}

// .show-reply {
//     transform: rotate(180deg);
//     // @include transition-default
//     // transition:5s;
//     // transition-delay: 5s;
// }

// .btn-assist {
//     position: fixed !important;
//     // z-index: 2;
//     bottom: 300px;
//     right: 0px;
//     background-color: var(--solids-grey-200);
//     border-bottom-left-radius: 60px;
//     border-top-left-radius: 60px;
//     height: 26px;
//     width: 20px;
//     overflow: hidden;
//     @include transition-default;
//     &:hover,
//     &:focus {
//         width: 95px;
//     }
//     // @include respond(phone){
//     //     bottom: 45px;
//     // }
// }

// @include respond(phone) {
//     margin-left: 0;
//     overflow-x: hidden;
// }
.review-filter-collapsible {
    min-width: 400px;
    @include respond(phone) {
        min-width: 200px;
    }
}
