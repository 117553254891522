.feedback-dialog{
    // background-color: red;
    &__input-box{
        display: flex;
        flex-direction: row;
        // border: 10px solid black;
        @include respond(phone) {
            display: flex;
            flex-direction: column;
          }
          &__type{
              margin-left: 10px;
              @include respond (phone){
                  margin-top: 12px;
                  margin-left: 0px;
              }
          }
    }
}