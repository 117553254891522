.card-report {
    min-height: 320px;
    // width: 45%;
    padding: 1%;
    border-radius: 8px;

    background-color: white;
    text-align: center;
    position: relative;
    // box-shadow: 2px 2px 10px #7f7f7f;
    box-shadow: 0 2px 5px rgba(var(--light-box-shadow), 0.1), 0 10px 20px rgba(var(--light-box-shadow), 0.1);
    margin-left: $even-spacing * 3;
    margin-right: $even-spacing * 3;
    margin-top: $even-spacing * 3;
    margin-bottom: $even-spacing * 3;

    @include respond(tab-port) {
        // height: unset;
        width: calc(100% - (#{$even-spacing} * 6));
        padding: 2%;
    }
    .card-header {
        border-bottom: 1px solid #f4f4f4;
        font-size: $txt-18;
        color: var(--primary-text-color);
        padding: 12px 8px;
        &__title {
            font-weight: 500;
        }
        @include respond(phone) {
            // font-size: 0.8rem !important;
            // padding-left: 2px !important;
            text-align: left;
        }

        button:active {
            animation: rightSpinQuarter 0.1s linear forwards;
        }
        img {
            width: 20px;
            height: 20px;
            &:active {
                animation: rightSpinQuarter 0.1s linear forwards;
            }
        }
    }
    .card-body {
        padding-top: 6px;
        .select-box {
            width: 25%;
            @include respond(phone) {
                width: 50%;
            }
        }
    }
}
