.scroll-hidden {
  overflow: hidden;
}

%v-scroll-auto {
  overflow: auto;
}
.v-scroll-auto {
  @extend %v-scroll-auto;
}

.custom-scroll {
  @extend %v-scroll-auto;
  // scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.3);

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    width: 4px;
    height: 3px;
    border-radius: 2px;
  }
}
.custom-scroll-super-slim {
  @extend %v-scroll-auto;
  scrollbar-width: thin;
  scrollbar-color: rgba(70, 70, 70, 0.616);
  visibility: hidden;
  @include respond(tab-land) {
    visibility: visible;
  }
  .content,
  &:hover,
  &:focus,
  &:active {
    visibility: visible;
  }

  &::-webkit-scrollbar {
    width: 2px;
    height: 2px;
    -webkit-appearance: none;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(70, 70, 70, 0.616);
    width: 2px;
    height: 2px;
    border-radius: 2px;
  }
}
