#contact-settings-area {
    margin-right: auto;
    margin-left: auto;
    margin-bottom: $even-spacing * 4;
    padding: 0 $even-spacing * 2;
    max-width: $small-desktop-breakpoint;

    .feature {
        display: inline-block;
        &__image {
            height: 25px;
            width: 25px;
            margin: 0px 5px;
        }
    }

    h1,
    h2,
    h3,
    h4 {
        overflow-wrap: break-word;
        word-break: break-word;
    }

    // @include respond(tab-port) {
    //     margin-right: 8px;
    // }
}
.contacts-dialog {
    .grid-container {
        display: grid;

        grid-template-columns: 1fr 1fr;
        grid-column-gap: $even-spacing * 3;
        grid-row-gap: 4px;

        @include respond(phone) {
            grid-row-gap: 4px;
            grid-template-columns: 1fr;
        }
    }
    .feature-container {
        $self: ".feature";
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        #{$self} {
            display: flex;
            justify-content: center;
            margin-right: 10px;
            &__image {
                height: 25px;
                width: 25px;
                margin-right: 5px;
            }
            &__label {
                @extend %txt;
                color: var(--primary-text-color);
                font-weight: bold;
            }
        }
    }
    .lead-container {
        display: grid;
        grid-template-columns: 4fr 8fr;
        row-gap: 10px;
    }
    .flex-wrap-phone {
        @include respond(phone) {
            flex-wrap: wrap;
        }
    }
}
