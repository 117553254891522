.walkthrough {
    &__header {
        @extend %txt-imp;
        // border: 1px solid;
        background-color: rgba(255, 255, 255, 0.4) !important;
        border-radius: 5px !important;
    }
    &__tooltip {
        @extend %txt-imp;
        border-radius: 10px !important;
        $self: ".introjs";
        #{$self}-tooltiptext {
            padding: 15px 20px;
            @extend %txt-imp;
        }
        #{$self}-bullets {
            padding-top: 0px;
            padding-bottom: 10px;
        }
        #{$self}-tooltip-header {
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 10px;
        }
        #{$self}-skipbutton {
            font-size: $txt-16;
            padding: 5px 5px;
            @extend %txt-imp;
        }
        #{$self}-tooltipReferenceLayer * {
            @extend %txt-imp;
        }

        #{$self}-nextbutton,
        #{$self}-prevbutton {
            border-radius: 6px;
        }
        #{$self}-button {
            @extend %txt-imp;
            color: white;
            background-color: var(--primary-color) !important;
            &:hover {
                background-color: var(--primary-color-dark) !important;
            }
        }
    }
}
