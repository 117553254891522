/* This file is need to be created for support the background url property which is causing failed of production build if used in scss. 
The error was: "error: resolve-url-loader: css error source-map information is not available at url() declaration (found orphan cr, try removecr option)"*/
@font-face {
  font-family: 'icomoon';
  src:  url('../../assets/fonts/icomoon.eot?qypu6z');
  src:  url('../../assets/fonts/icomoon.eot?qypu6z#iefix') format('embedded-opentype'),
    url('../../assets/fonts/icomoon.ttf?qypu6z') format('truetype'),
    url('../../assets/fonts/icomoon.woff?qypu6z') format('woff'),
    url('../../assets/fonts/icomoon.svg?qypu6z#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.login__left-sec.carchat24 {
  background: #004a9e url(../../assets/images/carchat24/login/login-bg.png) center no-repeat;
}
.login__left-sec.boatchat {
  background: #004a9e url(../../assets/images/boatchat/login/login-bg.png) center no-repeat;
}
.login__left-sec.rvchat {
  background: #004a9e url(../../assets/images/rvchat/login/login-bg.png) center no-repeat;
}
.login__left-sec.legalchat {
  background: #eb8f6a url(../../assets/images/legalchat/login/login-bg.png) center no-repeat;
}
.login__left-sec.chatlead {
  background: #004a9e url(../../assets/images/chatlead/login/login-bg.png) center no-repeat;
}
.login__left-sec.servicespro {
  background: #004a9e url(../../assets/images/servicespro/login/login-bg.png) center no-repeat;
}

/* @media only screen and (max-width: 769px) {
  .login__left-sec.carchat24 {
    background: #004a9e url(../../assets/images/carchat24/login/login-bg-transparent.png) no-repeat center;
  }
  .login__left-sec.boatchat {
    background: #004a9e url(../../assets/images/boatchat/login/login-bg-transparent.png) no-repeat center;
  }
  .login__left-sec.rvchat {
    background: #004a9e url(../../assets/images/rvchat/login/login-bg-transparent.png) no-repeat center;
  }
  .login__left-sec.legalchat {
    background: #004a9e url(../../assets/images/legalchat/login/login-bg-transparent.png) no-repeat center;
  }
  .login__left-sec.chatlead {
    background: #004a9e url(../../assets/images/chatlead/login/login-bg-transparent.png) no-repeat center;
  }
  .login__left-sec.servicespro {
    background: #004a9e url(../../assets/images/servicespro/login/login-bg-transparent.png) no-repeat center;
  }
} */

.process-payment .ticket {
  clip-path: url("#ticket-clip-path");
}

.navbar .bottom-area .profile-image::before {
  background-image: url(../../assets/images/user-avatar.png);
}
