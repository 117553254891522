#settings-form {
    width: $tab-port-breakpoint;
    margin: 0 auto;
    .form-grid {
        $self: ".form-grid";

        &-container {
            display: grid;
            grid-template-columns: minmax(200px, 1fr) minmax(200px, 1fr);
            gap: 12px 8px;
            
            @include respond(phone) {
                grid-template-columns: minmax(200px, 1fr);
                // margin-right: 8px;
            }
            #{$self}-item {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 12px 8px;

                // @include respond(phone) {
                //     width: calc(100% - 72px);
                // }
            }
        }
    }
    @include respond(tab-land) {
        width: 100%;
    }
}
