.dept-based-routing {
    &__input-container {
        width: 80%;
        margin: auto;

        @include respond(phone) {
            width: 90%;
        }
    }

    &__switch {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 16px;
    }

    &__subscriber-input-container {
        width: 50%;
        margin: auto;

        @include respond(phone) {
            width: 90%;
        }
    }
}