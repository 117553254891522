.lead-routing-table {
    width: 65% !important;
    margin-bottom: 20px !important;
    @include respond(phone) {
        width: 100% !important;
    }
    .MuiTableRow-root.MuiTableRow-hover:hover {
        background-color: unset;
    }
    .grid-container {
        display: grid;
        grid-template-areas:
            "a b"
            "c c";
        grid-template-columns: 1fr 1fr;
        .contacts-column {
            grid-area: a;
            padding: 5px 3px 4px 15px;
            // @include respond(phone) {
            //     word-break: break-all;
            // }
            word-break: break-all;
        }
        .service-mode-column {
            grid-area: b;
        }
        .actions {
            background-color: var(--surface-color2);
            display: flex;
            justify-content: flex-end;
            grid-area: c;
            color: var(--primary-color);
            // border-radius: 4px;
            // border-top: 1px solid #e3ebff;
            padding: 2px 2px;
            /* button {
                background-color: white;
                color: var(--primary-color);
                border: none;
                box-shadow: none;
                cursor: pointer;
            } */
        }
    }
    thead {
        background-color: var(--primary-color);
        tr {
            // background-color: rgb(253, 255, 240);
            color: white;

            td {
                font-size: 0.925rem;
            }
            td:first-child {
                width: 50%;
            }
            td:nth-child(2) {
                padding-left: 2px;
            }
        }
    }
    & > tbody {
        // & > tr:nth-child(even) {
        //     .service-mode-column {
        //         background-color: rgb(99, 201, 142);
        //         color: white;
        //         // width: 60%;
        //     }
        // }
        // & > tr:nth-child(odd) {
        //     .service-mode-column {
        //         background-color: #594fe9;
        //         color: white;
        //         // width: 60%;
        //     }
        // }
        & > tr {
            .service-mode-column {
                table {
                    border-collapse: collapse;
                    tr {
                        color: var(--primary-color);
                    }
                    tr:nth-child(even):not(.no-data) {
                        td:first-child {
                            border-left: 2px solid var(--warning-yellow-font);
                        }
                    }
                    tr:nth-child(odd):not(.no-data) {
                        background-color: #f0fbf1;
                        td:first-child {
                            border-left: 2px solid #63c98e;
                        }
                    }
                    .no-data {
                        border-left: 2px solid var(--danger-red-font);
                        color: var(--danger-red-font);
                        font-style: italic;
                    }
                }
            }
        }
        td {
            padding: 0px;
        }

        table {
            td:first-child {
                padding: 8px 0px 8px 16px;
            }
            td:last-child {
                padding: 8px 8px 8px 0px;
            }
        }

        .username-div {
            // font-weight: bold;
            font-size: 0.975rem;
            padding-bottom: 2px;
        }
        .contacts-div {
            color: var(--primary-color-dark);
        }
        .lead-table-cell {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            div {
                min-height: 30px;
            }
        }

        table {
            tr {
                td:last-child {
                    text-align: right;
                }
            }
        }
    }
}
.lead-routing-option {
    display: grid;
    width: 100%;
    grid-template-columns: 2fr 10fr;
    grid-template-areas:
        "d a"
        "d b"
        "d c";
    .grid-a {
        grid-area: a;
        word-break: break-all;
    }
    .grid-b {
        grid-area: b;
        color: var(--primary-color);
        word-break: break-all;
    }
    .grid-c {
        grid-area: c;
        color: var(--primary-color);
        word-break: break-all;
    }
    .grid-d {
        grid-area: d;
        color: #63c98e;
    }
}
