.ck {
    &.ck-editor__editable_inline {
        ul,
        ol {
            margin-left: calc(2 * var(--ck-spacing-standard));
        }
    }
    &.ck-error{
        border: 1px solid var(--danger-red-font) !important;
        border-radius: 3px !important;
    }
}
