.scripted-response {
    &__header {
        // width: 100%;
        // height: 60px;
        padding-top: 15px;
        padding-bottom: 15px;
        background-color: var(--primary-color-dark);
        color: var(--surface-color);
        font-size: $txt-18;
    }
    &__type {
        font-size: $txt-16;
    }
    &__reponse-list {
        & > div {
            span {
                font-size: $txt-12;
            }
            &:first-child {
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
            }
            &:last-child {
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
            }
            &:nth-child(odd) {
                background-color: var(--surface-color);
            }
            &:nth-child(even) {
                background-color: var(--surface-color3);
            }
            &:hover {
                background-color: var(--muted-text-color2);
                // color: var(--);
            }
        }
    }
}
