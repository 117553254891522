.ui-file-dropzone {
    margin-bottom: 10px;
    .file-drop-container {
        .dropzone-area {
            text-align: center;
            padding: 40px 20px;
            border: 2px dashed rgba(0, 0, 0, 0.23);
            border-radius: 10px;
            // background-color: var(--solids-dark-1);
            color: black;
            min-width: 500px;
            margin-bottom: 10px;
            &.dropzone-area:hover {
                border: 2px dashed black;
            }
            &.fileborder {
                border: 2px dashed var(--primary-color);
                color: var(--primary-color);
                &:hover {
                    border: 2px dashed var(--primary-color);
                }
            }
            &.disabled {
                border: 3px dashed var(--surface-color2);
                color: var(--surface-color2);
            }
            @include respond(phone) {
                min-width: 200px;
            }
        }
        .preview-area {
            .preview {
                border-radius: 50%;
                width: 200px;
                height: 200px;
                margin: 0 auto;
                position: relative;
                overflow: hidden;
                border: 2px solid var(--surface-color2);
                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}
