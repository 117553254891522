.number-registration {
    .input-container {
        width: 50%;
        @include respond(phone) {
            width: 100%;
        }
    }
    .title-details{
        font-size: $txt-16;
        color: var(--primary-color);
        font-weight: bold;
    }
    
    
}


.new-number-panel {
    &__header {
        // width: 100%;
        // height: 60px;
        padding-top: 15px;
        padding-bottom: 15px;
        background-color: var(--primary-color-dark);
        color: var(--surface-color);
        font-size: $txt-18;
    }
}

.select-number-text{
    font-size: $txt-8;
}


