.sales-portal-agreement {
    .input-container {
        width: 50%;
        @include respond(phone) {
            width: 100%;
        }
    }
    @include respond(phone) {
        margin-left: 0;
        overflow-x: hidden;
    }
}
