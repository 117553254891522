.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-error {
  color: var(--danger-red-font);
  font-size: $txt-14;
}

div.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-muted {
  color: var(--muted-text-color);
}

.text-color-primary {
  color: var(--primary-text-color);
}

.text-bold {
  font-weight: bold;
}

.text-center {
  text-align: center;
}

%txt {
  font-family: "-apple-system", "BlinkMacSystemFont", "Poppins", '"Segoe UI"', "Roboto", '"Helvetica Neue"', "Arial",
    "sans-serif", '"Apple Color Emoji"', '"Segoe UI Emoji"', '"Segoe UI Symbol"';
}

%txt-imp {
  font-family: "-apple-system", "BlinkMacSystemFont", "Poppins", '"Segoe UI"', "Roboto", '"Helvetica Neue"', "Arial",
    "sans-serif", '"Apple Color Emoji"', '"Segoe UI Emoji"', '"Segoe UI Symbol"' !important;
}

$sizes: 8, 10, 12, 14, 16, 18, 20, 24;

@each $size in $sizes {
  .txtSize-#{$size} {
    font-size: rem($size);
  }
  .txtSize-#{$size}-imp {
    font-size: rem($size) !important;
  }
}
