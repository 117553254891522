.desktop-only {
    @include respond(desktop) {
        display: block;
    }
    @include respond(tab-land) {
        display: block;
    }
    @include respond(tab-port) {
        display: none;
    }
    @include respond(phone) {
        display: none;
    }
}
.mobile-only {
    display: none;
    @include respond(desktop) {
        display: none;
    }
    @include respond(tab-land) {
        display: none;
    }
    @include respond(tab-port) {
        display: block;
    }
    @include respond(phone) {
        display: block;
    }
}

.loader {
    border: 2px solid transparent;
    border-top: 2px solid white;
    border-right: 2px solid white;
    border-bottom: 2px solid white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s ease-in infinite;
}

.LoaderToastify {
    &__toast-container {
        width: max-content !important;
        @include respond(phone) {
            margin-top: 1rem !important;
            left: 50% !important;
            transform: translateX(-50%) !important;
        }
    }
    &__toast {
        min-height: 0px !important;
        border-radius: 50px !important;
        text-align: center;
        padding: 2px !important;
    }
}

.mobile-full-width {
    @include respond(tab-port) {
        width: 100% !important;
    }
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:focus {
    // -webkit-text-fill-color: green;
    box-shadow: 0 0 0px 1000px white inset;
}

// input:-webkit-autofill:hover,
// textarea:-webkit-autofill:hover,
// select:-webkit-autofill:hover {
//     // -webkit-text-fill-color: green;
//     // box-shadow: 0 0 0px 1000px rgba(0, 0, 0, 0.005) inset;
// }

.pointer {
    cursor: pointer;
}
.p-e-none {
    cursor: default;
    pointer-events: none;
}
.overflow-h {
    overflow: hidden;
}
.sixteen-by-nine.aspect-ratio {
    padding-bottom: 56.25%;
}

.universal-box-shadow {
    box-shadow: $box-shadow1 !important;
    &:hover {
        box-shadow: $box-shadow1-hover;
    }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    _::-webkit-full-page-media,
    _:future,
    iframe.webkit-height {
        // @supports (-webkit-appearance: none) {
        //     -webkit-logical-height: -webkit-fill-available;
        // }
        -webkit-logical-height: -webkit-fill-available;
    }
}

.rotate-180Deg {
    animation: rightSpinHalf 0.1s linear forwards;
}

.rotate-90Deg {
    animation: rightSpinQuarter 0.1s linear forwards;
}

.active\:rotate-90Deg {
    &:active {
        animation: rightSpinQuarter 0.1s linear forwards;
    }
}

.color-error {
    color: #f44336;
}
.color-waring {
    color: var(--warning-yellow-font);
}
.circle-indicator {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    margin: 0px 5px;
    &.success {
        background-color: var(--success-green-font);
    }
    &.error {
        background-color: var(--danger-red-font);
    }
    &.warning {
        background-color: var(--warning-yellow-font);
    }
    &.info {
        background-color: var(--muted-text-color2);
    }
}

#g-recaptcha {
    z-index: 2;
    position: absolute;
    .grecaptcha-badge {
        bottom: 40px !important;
    }
}

kbd {
    background-color: var(--surface-color2);
    border-radius: 3px;
    border: 1px solid var(--solids-grey-800);
    box-shadow: 0 1px 1px var(--solids-grey-200);
    color: var(--primary-text-color);
    display: inline-block;
    font-size: $txt-14;
    font-weight: 700;
    line-height: 1;
    padding: 2px 4px;
    // white-space: nowrap;
}
.vertical-line {
    border-left: 2px solid var(--solids-grey-light3);
}
.highlight-field {
    background-color: var(--muted-text-color2);
}
