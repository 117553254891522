$variable: (
  "all": (
    "colors": (
      "light": (
        "primary-color": #0058ff,
        "primary-color-dark": #0046c7,
        "primary-color-light": #92b8ff,
        "secondary-color": #ffd200,

        "navbar-background": #002568,
        "primary-text-color": #112e72,
        "navbar-text-color": #fff,

        "muted-text-color": #5b95cf,
        "muted-text-color2": #c3d1e4,

        "surface-color": #f2f5fc,
        "surface-color2": #efeeff,
        "surface-color3": #bedbff,
        "surface-color-yellow": #fff7cf,

        "solids-white": #fff,
        "solids-black": #000,
        "solids-grey-800": #263238,
        "solids-grey-light": #e5e5e5,
        "solids-grey-light2": #e7e9eb,
        "solids-grey-light3": #e2dfdf,
        "solids-grey-400": rgba(0, 0, 0, 0.80),
        "solids-grey-200": rgba(0, 0, 0, 0.54),
        "solids-dark-blue": #072464,
        "solids-green-light": #0aeeb1,

        "danger-red-bg": #ffdbe9,
        "danger-red-font": #e90162,

        "warning-yellow-bg": #fff0db,
        "warning-yellow-font": #fba55a,

        "success-green-bg": #e1f8e4,
        "success-green-font": #29bf78,

        "cl-brand-color": #0058ff,

        "transparent-border": rgba(255, 255, 255, 0),
        "light-box-shadow": "0, 98, 255",
        "light-box-shadow2": "20, 95, 218",

        "filter-btn-color": "167, 215, 255",
        "filter-btn-color-hover": "142, 203, 253",
      ),
    ),
  ),
  "legalchat": (
    "colors": (
      "light": (
        "primary-color": #f15d22,
        "primary-color-dark": #f34a07,
        "primary-color-light": #f19a78,
        "secondary-color": #04a777,

        "navbar-background": #f7efd3,
        "primary-text-color": #0d8a72,
        "navbar-text-color": #0d8a72,

        "muted-text-color": #5995cf,
        "muted-text-color2": #b2e99c,

        "surface-color": #fbfbf7,
        "surface-color2": #fbead8,
        "surface-color3": #ffd3be,
        "surface-color-yellow": #fff7cf,
        
        "solids-white": #fff,
        "solids-black": #000,
        "solids-grey-800": #263238,
        "solids-grey-light": #e5e5e5,
        "solids-grey-light2": #e7e9eb,
        "solids-grey-light3": #e2dfdf,
        "solids-grey-400": rgba(0, 0, 0, 0.80),
        "solids-grey-200": rgba(0, 0, 0, 0.54),
        "solids-dark-blue": #072464,
        "solids-green-light": #0aeeb1,

        "danger-red-bg": #ffdbe9,
        "danger-red-font": #e90162,

        "warning-yellow-bg": #fff0db,
        "warning-yellow-font": #fba55a,

        "success-green-bg": #e1f8e4,
        "success-green-font": #29bf78,

        "cl-brand-color": #f15d22,

        "transparent-border": rgba(255, 255, 255, 0),
        "light-box-shadow": "229, 146, 45",
        "light-box-shadow2": "218, 139, 20",

        "filter-btn-color": "241, 180, 120",
        "filter-btn-color-hover": "250, 190, 120",

        "ck-color-focus-border-coordinates": "43,79%,51%",
      ),
    ),
  ),
);

$border-1: 1px solid #d3d3d3;
$box-shadow1: 0 2px 5px rgba(var(--light-box-shadow), 0.1), 0 10px 20px rgba(var(--light-box-shadow), 0.1);
$box-shadow1-hover: 0 1px 3px rgba(var(--light-box-shadow), 0.1), 0 5px 15px rgba(var(--light-box-shadow), 0.1);
$desktop-nav-bar-width: 256px;
$desktop-nav-bar-collapsed-width: 85px;

$all: map-get(
  $map: $variable,
  $key: "all",
);
$legalchat: map-get(
  $map: $variable,
  $key: "legalchat",
);

$colors: map-get(
  $map: $all,
  $key: "colors",
);
$legalchat-colors: map-get(
  $map: $legalchat,
  $key: "colors",
);

$light-colors: map-get(
  $map: $colors,
  $key: "light",
);
$legalchat-light-colors: map-get(
  $map: $legalchat-colors,
  $key: "light",
);

$xs-phone-breakpoint: 321px;
$phone-breakpoint: 576px;
$tab-port-breakpoint: 769px;
$tab-land-breakpoint: 1025px;
$small-desktop-breakpoint-1: 1072px;
$small-desktop-breakpoint: 1280px;
$desktop-breakpoint: 1368px;
$big-desktop-breakpoint: 1800px;

$txt-8: rem(8);
$txt-10: rem(10);
$txt-12: rem(12);
$txt-14: rem(14);
$txt-16: rem(16);
$txt-18: rem(18);
$txt-20: rem(20);
$txt-24: rem(24);
$txt-28: rem(28);
$txt-32: rem(32);
$txt-36: rem(36);
$txt-40: rem(40);
$txt-42: rem(42);
$txt-52: rem(52);
$txt-54: rem(54);
$txt-64: rem(64);
$txt-70: rem(70);
$txt-72: rem(72);
$txt-75: rem(75);
$txt-90: rem(90);
$txt-104: rem(104);
$txt-150: rem(150);
$txt-200: rem(200);
$txt-250: rem(250);

$spacing-1: 2px;
$even-spacing: 4px;
$b-radius: 4px;

// .mr-20 {
//   margin-right: 20px;
// }

:root {
  @each $name, $color in $light-colors {
    --#{$name}: #{$color};
  }
}
.prod-legalchat {
  @each $name, $color in $legalchat-light-colors {
    --#{$name}: #{$color};
  }
}

// @each $name, $color in $light-colors {
//   $name: #{$color};
// }
