.api-keys-page {
    .api-key-card {
        min-height: 200px;
        max-width: $small-desktop-breakpoint-1;
        padding: 20px 30px;
        border-radius: 8px;

        background-color: white;
        text-align: center;
        position: relative;
        // box-shadow: 2px 2px 10px #7f7f7f;
        box-shadow: 0 2px 5px rgba(var(--light-box-shadow), 0.1), 0 10px 20px rgba(var(--light-box-shadow), 0.1);
        margin-left: auto;
        margin-right: auto;
        margin-top: $even-spacing * 5;
        margin-bottom: $even-spacing * 3;
        p {
            color: var(--muted-text-color)
        }

        // .form-container {
        //     width: 90%;
        // }
        .select-box {
            width: 25%;
            // height: 50%;
            @include respond(phone) {
                width: 50%;
            }
        }
    }
}
