.btn-help {
    position: fixed !important;
    z-index: 2;
    bottom: 60px;
    right: 0px;
    background-color: var(--solids-grey-200) ;
    border-bottom-left-radius: 50px;
    border-top-left-radius: 50px;
    height: 26px;
    width: 26px;    
    overflow: hidden  ;
    @include transition-default;
    &:hover,&:focus {
      width: 65px;
    }
    @include respond(phone){
        bottom: 45px;
    }
}
  
