.top-level-navbar {
  position: relative;
}
.content-wrapper {
  position: relative;
}

// .route-transition-fade {
//   $transClass: ".route-transition-fade";
//   &#{$transClass}--enter {
//     opacity: 0;
//   }
//   &#{$transClass}--enter-active {
//     opacity: 1;
//     transition: opacity 200ms;
//   }
//   &#{$transClass}--exit {
//     opacity: 1;
//   }
//   &#{$transClass}--exit-active {
//     opacity: 0;
//     transition: opacity 1ms;
//   }
// }

// .route-transition {
//   $transClass: ".route-transition";
//   &#{$transClass}--enter {
//     opacity: 0;
//     transform: translateX(100%);
//   }
//   &#{$transClass}--enter-active {
//     opacity: 1;
//     transform: translateX(0%);
//     transition: all 200ms;
//   }
//   &#{$transClass}--exit {
//     opacity: 1;
//     transform: translateX(0%);
//   }
//   &#{$transClass}--exit-active {
//     opacity: 0;
//     transform: translateX(100%);
//     transition: all 1ms;
//   }
// }

// .subscriber-switch {
//   &.wrapper {
//     max-width: 230px;
//     margin: 10px $spacing-1 * 5;
//     overflow: hidden;

//     .btn--subscriber-switch {
//       height: 48px;
//     }

//     .back-to-admin {
//       width: 50px;
//       padding: 5px;
//     }
//   }
// }

.subscriber-switch {
  &.wrapper {
    max-width: 230px;
    margin: 10px auto;
    // overflow: hidden;

    .sub-name-container {
      background-color: var(--secondary-color);
      padding: 13.5px 20px;
      border-radius: 6px;
      color: var(--solids-grey-800);
      font-weight: 500;
      &.minimal {
        padding: {
          top: 10px;
          bottom: 10px;
        }
      }
      .sub-name {
        max-width: 150px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: $txt-14;
      }
    }

    .btn--subscriber-switch {
      height: 48px;
    }

    // .back-to-admin {
    //   width: 50px;
    //   padding: 5px;
    // }

    .btn--bta {
      margin-left: 5px;
    }
  }
  &.minimal {
    .btn--subscriber-switch {
      height: 40px;
    }
    // .back-to-admin {
    //   width: unset;
    //   .btn--bta {
    //     height: 30px;
    //   }
    // }
    .btn--bta {
      height: 30px;
      margin-left: 0px;
      margin-top: 5px;
    }
  }
}
